//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";

//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { fr } from "date-fns/locale";
import { format, parseISO, formatISO } from "date-fns";
import { Divider, FormHelperText } from "@mui/material";



const ReleaseDialog = ({ isOpen, onClose, referential_immutable_id }) => {
    const theme = useTheme();
    const { httpRequest } = useHttp();
    const { paths } = usePaths();
    const { presentToast } = useToast();
    const [randomCode, setRandomCode] = useState("");
    const { control, handleSubmit, reset } = useForm({
        mode: "all"
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            setRandomCode(Math.random().toString().substring(2, 8));
            reset();
        }
    }, [isOpen])

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {
        let response = await httpRequest({
            url: getServerUrl() + "/admin/referential/release",
            data: { referential_immutable_id: referential_immutable_id, HAS_date: data.HAS_date },
            headers: { "Content-type": "Application/json" },
            method: 'post',
            withCredentials: true
        });
        if (response.status === 200) {
            presentToast({
                message: "Référentiel publié avec succès",
                severity: "success",
            });
            onClose();
            navigate(paths.referentialReleased.app());
        }
    })

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="lg"
                open={isOpen}
            // onClose={ }
            >
                <DialogTitle>
                    Publier référentiel
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ overFlowY: "auto" }}>
                        <Controller
                            name="HAS_date"
                            rules={{ required: "Date requise" }}
                            defaultValue={null}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl error={error?.message ? true : false}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                            <DatePicker
                                                value={value}
                                                onChange={onChange}
                                                label="Date référentiel HAS"
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                        <Box component="p">
                            <span style={{ fontWeight: "bold" }}>Attention:</span> la publication du référentiel est <span style={{ fontWeight: "bold" }}>irréversible.<br /></span> Confirmer en recopiant le code suivant: <span style={{ fontWeight: "bold" }}>{randomCode}</span>
                        </Box>
                        <Controller
                            name="codeCopy"
                            defaultValue={""}
                            rules={{
                                validate: (value) => {
                                    if (value !== randomCode) return "Code incorrect"
                                }
                            }}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl variant="standard" error={error?.message ? true : false}>
                                        <TextField
                                            autoFocus
                                            label="Code"
                                            fullWidth
                                            variant="standard"
                                            value={value}
                                            onChange={event => onChange(event?.target?.value)}
                                            error={error?.message ? true : false}
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { ReleaseDialog }