import { Backdrop, Box, Button, Chip, CircularProgress, FormControl, MenuItem, Select, Typography, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from "react";
import { createSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { getServerUrl } from "../../config";
import { useHttp } from "../../context/HttpProvider";
import { useUser } from "../../context/UserProvider";
import { useDateFilter } from "../../components/DateFilter";

/*****************************************************************************************************
 * Main Screen
 * @returns 
 *****************************************************************************************************/
const Logs = () => {
    const { httpRequest } = useHttp();
    const uiDateFilter = useDateFilter({});
    const { isLoggedIn, user } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState(undefined);
    const [customers, setCustomers] = useState(undefined);
    const [viewFilters, updateViewFilters] = useImmer({ customer_hid: 'admin' });

    const fetchCustomers = useCallback(async () => {
        setIsLoading(true);
        let query_url = `${getServerUrl()}/admin/customers/fetch`;

        let response = await httpRequest({
            url: query_url,
            headers: { "Content-type": "Application/json" },
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {

            setCustomers(response.data);
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchCustomers();
    }, [isLoggedIn, user])

    const fetchLogs = useCallback(async (sc_params) => {
        setIsLoading(true);
        let query_url = `${getServerUrl()}/admin/logs`;
        if (sc_params) {
            query_url += '?';
            for (const entry of sc_params.entries()) {
                query_url += `${entry[0]}=${entry[1]}&`
            }
            query_url = query_url.slice(0, -1);
        }
        let response = await httpRequest({
            url: query_url,
            headers: { "Content-type": "Application/json" },
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {

            setLogs(response.data);
        }
        setIsLoading(false);
    }, [])



    const onClickGoFilter = () => {
        let searchParams = {};
        uiDateFilter.addFilterValues(searchParams);
        searchParams.customer_hid = viewFilters.customer_hid;
        // viewFilters
        const sc_params = createSearchParams(searchParams);
        // navigate({ pathname: '/app/logs', search: `?${sc_params}` }, { replace: true });
        fetchLogs(sc_params);
    }

    const quickSearchToolbar = (
        <div style={{ display: 'flex', gap: '2rem', alignItems: "center" }}>
            <Typography variant="h6" color="inherit" component="div">
                {logs?.length} Logs
            </Typography>


            <FormControl variant="standard" style={{ width: '100px', marginRight: '15px' }}>
                <Select
                    value={viewFilters?.customer_hid}
                    onChange={(event) => {
                        updateViewFilters(draft => {
                            draft.customer_hid = event.target.value;
                        })
                    }}
                    id="filter_sm-log_select"
                >
                    <MenuItem key="admin" value="admin">Admin</MenuItem>
                    {customers?.map(s => (
                        <MenuItem value={s.human_id} key={"key_" + s.human_id}>
                            {s.legal_entity}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <uiDateFilter.DateFilter />



            <Button
                id="basic-button"
                variant="contained"
                onClick={onClickGoFilter}
            >Go
            </Button>

        </div>)

    const columns = useMemo(
        () => [
            {
                accessorKey: 'level',
                header: 'Level',
                size: 50,
                Cell: ({ cell }) => {
                    let color = "primary";
                    switch (cell.getValue()) {
                        case 'error':
                            color = "error";
                            break;
                        case 'warning':
                            color = "warning";
                            break;
                        case 'debug':
                            color = "secondary";
                            break;
                        default:
                            break;
                    }
                    return <Chip label={cell.getValue()} color={color} onClick={() => {
                        if (cell.getValue() !== 'error') {
                            return;
                        }
                        const row = cell.row;
                        alert('Message: ' + row.original.meta.exceptionMessage + '\n' + 'Stack: ' + row.original.meta.exceptionStack);
                    }} />
                }
            },
            {
                accessorKey: 'meta.log_id',
                header: 'Log Id',
                size: 60,
            },
            {
                accessorKey: 'meta.user_name',
                header: 'User',
                size: 60,
                Cell: ({ cell }) => {
                    return <Tooltip title={cell.getValue()}><Typography sx={{ fontSize: "inherit" }}>{cell.getValue()}</Typography></Tooltip>
                }
            },
            {
                accessorKey: 'meta.path',
                header: 'Path',
                size: 120,
                Cell: ({ cell }) => {
                    return <Tooltip title={cell.getValue()}><Typography sx={{ fontSize: "inherit" }}>{cell.getValue()}</Typography></Tooltip>
                }
            },
            {
                accessorKey: 'message',
                header: 'Message',
                size: 200,
                Cell: ({ cell }) => {
                    return <Tooltip title={cell.getValue()}><Typography sx={{ fontSize: "inherit" }}>{cell.getValue()}</Typography></Tooltip>
                }
            },
            {
                accessorKey: 'timestamp',
                header: 'Créée le',
                size: 140,
                Cell: ({ cell }) => {
                    return <>{cell.getValue() ? moment(cell.getValue()).format('DD-MM-YY HH:mm:ss') : ''}</>
                }
            },

        ],
        [],
    );

    return (
        <Box sx={{ width: '100%' }}>

            <MaterialReactTable
                columns={columns}
                data={logs || []}
                muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 180px)' } }}
                enablePagination={false}
                enableBottomToolbar={false}
                enableDensityToggle={true}
                enableExpanding
                getRowCanExpand={(row) => row.original.level === 'error'} // Only allow expansion if level is 'error'
                renderDetailPanel={({ row }) => (
                    row.original.level === 'error' ? (
                        <Box sx={{ padding: '10px', backgroundColor: '#f0f0f0' }}>
                            <Typography variant="body1" color="error">
                                <strong>Stack Trace:</strong>
                            </Typography>
                            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                                {row.original.meta.exceptionStack || 'No Stack Trace Available'}
                            </Typography>
                        </Box>
                    ) : null
                )}
                initialState={{ density: 'compact' }}
                enableStickyHeader={true}
                renderTopToolbarCustomActions={({ table }) => {
                    return quickSearchToolbar;
                }}
                // Hide the expand icon when row cannot expand
                muiExpandButtonProps={({ row }) => ({
                    sx: { visibility: row.original.level === 'error' ? 'visible' : 'hidden' }
                })}
            />


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading === undefined ? false : isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Logs;