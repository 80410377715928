//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VerifiedIcon from '@mui/icons-material/Verified';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ApartmentIcon from '@mui/icons-material/Apartment';


//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { User, Role } from "../../models/User";
import { CustomersDialog } from "./CustomersDialog";
import Customer from "../../models/Customer";


//show list of all users that have any permission in the selected establishment, should it be validated or not

const Customers = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user } = useUser();
    const theme = useTheme();
    const { paths } = usePaths();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState(undefined);
    const emptyCustomersDialogState = { isOpen: false, title: "", customer: undefined };
    const [customersDialogState, setCustomersDialogState] = useState(emptyCustomersDialogState);


    const fetchCustomers = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: getServerUrl() + "/admin/customers/fetch",
            headers: { "Content-type": "Application/json" },
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {
            console.log(response.data);
            setCustomers(response.data.map(c => new Customer(c)));
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchCustomers();
    }, [isLoggedIn, user])


    const columns = useMemo(() => [
        {
            accessorKey: 'human_id',
            header: 'ID',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            accessorKey: 'legal_entity',
            header: 'Nom',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            accessorKey: 'capacity',
            header: 'Capacité',
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'contact_name',
            accessorFn: (row) => {
                return (`${row.contact.firstname} ${row.contact.lastname}`
                )
            },
            header: 'Nom contact',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            id: 'contact_tel',
            accessorFn: (row) => {
                return (
                    row?.contact?.tel
                )
            },
            header: 'Tel contact',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            id: 'contact_email',
            accessorFn: (row) => {
                return (
                    row.contact.email
                )
            },
            header: 'Email contact',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
    ], [customers]);

    const toolbar = (<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
            variant="outlined"
            onClick={() => setCustomersDialogState({
                isOpen: true,
                customer: new Customer({ clientAction: "new" }),
                title: `Nouveau client`
            })}
        >NOUVEAU CLIENT</Button>
    </Box>);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MaterialReactTable
                    columns={columns}
                    data={customers ? customers : []}
                    enableColumnOrdering
                    enableGlobalFilter //Material React Table global filter will only work if empty string instead of undefined
                    enableGrouping
                    enableEditing
                    enablePinning
                    enableColumnResizing
                    enableColumnFilterModes
                    editingMode="modal"
                    initialState={{ density: 'comfortable', showColumnFilters: true, columnPinning: { right: ["mrt-row-actions"] } }}
                    state={{ showSkeletons: isLoading }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Interactions',
                            size: 200,
                            Cell: ({ row }) => (
                                <>
                                    <IconButton
                                        onClick={() => setCustomersDialogState({
                                            isOpen: true,
                                            customer: row?.original,
                                            title: `Modifier client ${row?.original?.human_id}`
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => navigate(paths.establishments.app({ customer_hid: row?.original?.human_id }))}
                                    >
                                        <ApartmentIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => navigate(paths.licenses.app({ customer_hid: row?.original?.human_id }))}
                                    >
                                        <VerifiedIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => navigate(paths.customerUsers.app({ customer_hid: row?.original?.human_id }))}
                                    >
                                        <PeopleAltIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    }}
                    muiTableBodyCellProps={{ align: "center" }}
                    renderEmptyRowsFallback={() => {
                        return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{customers?.length > 0 ? 'Aucun résultat.' : 'Aucun client.'}</em></h3></div>
                    }}
                    // enableDensityToggle={false}
                    localization={MRT_Localization_FR}
                    enablePagination={false}
                    enableRowVirtualization
                    rowVirtualizerProps={{
                        overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                        estimateSize: () => customers?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                    }}
                    renderBottomToolbarCustomActions={({ table, row }) => toolbar}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            maxHeight: `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
                        }
                    })}
                />
            </Box>
            <CustomersDialog
                isOpen={customersDialogState.isOpen}
                onClose={async () => {
                    setCustomersDialogState(emptyCustomersDialogState);
                    fetchCustomers();
                }}
                title={customersDialogState.title}
                customer={customersDialogState.customer}
            />
        </>
    );
};

export default Customers;
