//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";
import { usePaths } from "../context/PathsProvider";
import { useToast } from "../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import eqpLogo from "../img/logo_only.png";
import eqpLogoText from "../img/logo_title.png"

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../config";



const Login = () => {
    const { loginUser } = useUser();
    const { httpRequest } = useHttp();
    const { paths } = usePaths();
    const { presentToast } = useToast();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: "auto" }}>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Connexion
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", maxHeight: "calc(100vh - 64px)" }}
                onSubmit={handleSubmit(async (data) => {
                    let response = await httpRequest({
                        url: `${getServerUrl()}/admin/login`,
                        method: "post",
                        data: { username: data.username, password: data.password },
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true
                    })
                    if (response.status === 200) {
                        loginUser();
                        navigate(paths.customers.app());
                    } else if (response.status === 401) {
                        presentToast({
                            message: "Nom d'utilisatour ou mot de passe invalide",
                            severity: "error"
                        })
                    }
                })}
            >
                {/* <Box
                    component="img"
                    sx={{ height: "30%", objectFit: "contain", margin: "3% 0 3% 0" }}
                    alt="e-qualiplus logo"
                    src={logo}
                /> */}
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", margin: "48px", gap: "12px" }}>
                    <img src={eqpLogo} alt="Logo EQP" style={{ height: "25vh", width: "auto" }} />
                    <img src={eqpLogoText} alt="Text Logo EQP" style={{ height: "5vh", width: "auto" }} />
                </Box>
                <TextField
                    label="Identifiant"
                    variant="outlined"
                    error={errors.username ? true : false}
                    helperText={errors.username?.message}
                    sx={{ width: "300px", margin: "0 auto 2% auto" }}
                    {...register("username", { required: "Identifiant requis" })}
                />
                <TextField
                    label="Mot de passe"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                    sx={{ width: "300px", margin: "0 auto 2% auto" }}
                    {...register("password", { required: "Mot de passe requis" })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ width: "200px", margin: "0 auto 0 auto" }}
                    type="submit"
                >
                    se connecter
                </Button>
            </Box>
        </Box>
    );
}

export default Login;