//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import MuiAppBar from '@mui/material/AppBar';
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import ArchiveIcon from '@mui/icons-material/Archive';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import NotesIcon from '@mui/icons-material/Notes';
import OutboundIcon from '@mui/icons-material/Outbound';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { usePaths } from "../context/PathsProvider";
import { useUser } from "../context/UserProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import EqpLogo from "../img/logo_only.png";
import EqpLogoText from "../img/logo_title.png";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { Collapse } from "@mui/material";





const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        alignItems: 'stretch',
        paddingTop: theme.appbarHeight,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    height: theme.appbarHeight
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    height: theme.appbarHeight,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar
}));


const AppToolbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const { establishments, user, selectedEstablishment, setSelectedEstablishment, handleClickLogout, customer } = useUser();
    const { paths, getCurrentPathObject } = usePaths();
    const [open, setOpen] = useState(false);
    const [userPopover, setUserPopover] = useState({ isOpen: false, anchorEl: undefined });
    const [referentialAccordion, setReferentialAccordion] = useState(false);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleReferentialAccordionClick = () => {
        setReferentialAccordion(ra => !ra);
    };

    const getPageTitle = () => {
        let currentPathObject = getCurrentPathObject();
        return currentPathObject?.title || "";
    }

    // const appPages = [
    //     {
    //         title: paths.customers.title,
    //         url: paths.customers.app(),
    //         icon: <HomeIcon />
    //     },
    //     {
    //         title: paths.referential.title,
    //         url: paths.referential.app(),
    //         icon: <QuestionMarkIcon />,
    //         subpages: [
    //             {
    //                 title: paths.referential.title + " brouillon",
    //                 url: 
    //             }
    //         ]
    //     }
    // ];

    return (
        <>
            <CssBaseline />
            <AppBar open={open} >
                <Toolbar sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {getPageTitle()}
                        </Typography>
                    </Box>
                    {establishments && selectedEstablishment &&
                        <Autocomplete
                            options={establishments}
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, value) => option?._id === value?._id}
                            disableClearable
                            value={selectedEstablishment}
                            onChange={(e, value) => {
                                setSelectedEstablishment(value)
                                let currentPathObject = getCurrentPathObject();
                                if (currentPathObject) {
                                    navigate(currentPathObject.app({
                                        customer_hid: customer.human_id,
                                        establishment_id: value._id,
                                        user_id: user._id
                                    }))
                                }
                            }}
                            sx={{ width: 300, backgroundColor: "white", borderRadius: "5px" }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    }
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%", flexGrow: "1", justifyContent: "right" }}>
                    </Box>
                    <Button aria-describedby="userPopover" color="secondary" variant="text" startIcon={<PersonOutlineIcon />} onClick={(event) => {
                        setUserPopover({ isOpen: true, anchorEl: event.currentTarget });
                    }}>{user?.firstname?.slice(0, 1)}.{user?.lastname}</Button>
                    <Popover
                        id={"userPopover"}
                        open={userPopover.isOpen}
                        anchorEl={userPopover.anchorEl}
                        onClose={() => { setUserPopover({ isOpen: false, anchorEl: undefined }) }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    // transformOrigin={{
                    //     vertical: "top",
                    //     horizontal: "right"
                    // }}
                    >
                        <List>
                            <ListItem onClick={handleClickLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Déconnexion</ListItemText>
                            </ListItem>
                        </List>
                    </Popover>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "20px", paddingLeft: "16px" }}>
                        <img src={EqpLogo} style={{ height: "56px", width: "auto" }} />
                        <img src={EqpLogoText} style={{ height: "24px", width: "auto" }} />
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Typography color="primary.light" sx={{ margin: "12px 0 0 20px" }}>Application</Typography>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton selected={location.pathname === paths.customers.app()} onClick={() => navigate(paths.customers.app())}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Clients"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleReferentialAccordionClick}>
                            <ListItemIcon>
                                <QuestionMarkIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Référentiel(s)"} />
                            {referentialAccordion ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={referentialAccordion} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => { navigate(paths.referentialDraft.app()); }}
                                selected={location.pathname === paths.referentialDraft.app()}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary={paths.referentialDraft.menu} />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => { navigate(paths.referentialReleased.app()); }}
                                selected={location.pathname === paths.referentialReleased.app()}>
                                <ListItemIcon>
                                    <OutboundIcon />
                                </ListItemIcon>
                                <ListItemText primary={paths.referentialReleased.menu} />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => { navigate(paths.referentialArchived.app()); }}
                                selected={location.pathname === paths.referentialArchived.app()}>
                                <ListItemIcon>
                                    <ArchiveIcon />
                                </ListItemIcon>
                                <ListItemText primary={paths.referentialArchived.menu} />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={() => { navigate(paths.logs.app()); }}
                        selected={location.pathname === paths.logs.app()}>
                        <ListItemIcon>
                            <NotesIcon />
                        </ListItemIcon>
                        <ListItemText primary={paths.logs.menu} />
                    </ListItemButton>
                </List>
                <Divider />
            </Drawer>
            <Main open={open}>
                <Outlet />
            </Main>
        </>
    );
}

export { AppToolbar };
