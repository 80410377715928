
class License {
    constructor(l) {
        this._id = l?._id;
        this.establishment_id = l?.establishment_id;
        this.establishment = l?.establishment;
        this.type = l?.type;
        this.access = l?.access;
        this.module = l?.module;
        this.start_date = l?.start_date;
        this.activation_date = l?.activation_date;
        this.expiration_date = l?.expiration_date;
        this.user_id = l?.user_id;
        this.user = l?.user;
        this.status = {
            value: l?.status?.value,
            created: {
                by: l?.status?.created?.by,
                on: l?.status?.created?.on
            }
        }
        this.clientAction = l?.clientAction;
    }
}

export default License;


