import Address from "./Address";
import Contact from "./Contact";

class Establishment {
    constructor(e) {
        this._id = e?._id;
        this.customer_id = e?.customer_id;
        this.customer_hid = e?.customer_hid;
        this.nb_licences = e?.nb_licences;
        this.legal_person = e?.legal_person;
        this.is_headquarter = e?.is_headquarter || false;
        this.name = e?.name;
        this.human_id = e?.human_id;
        this.capacity = e?.capacity;
        this.finess_no = e?.finess_no;
        this.website = e?.website;
        this.activity = e?.activity;
        this.director = new Contact({ ...e?.director, job: "directeur" });
        this.address = new Address(e?.address);
        this.contact = new Contact(e?.contact);
        this.unlocked_modules = e?.unlocked_modules;
        this.clientAction = e?.clientAction;
    }
}

export default Establishment;