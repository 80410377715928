
//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";

//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//









const ResetPasswordDialog = ({ isOpen, onClose, user }) => {
    const theme = useTheme();
    const { httpRequest } = useHttp();
    const [newPassword, setNewPassword] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');


    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useLayoutEffect(() => {
        if (isOpen) {
            setNewPassword(undefined);
        }
    }, [isOpen])

    const handleClickValidate = async () => {
        if (!newPassword) {
            let response = await httpRequest({
                url: `${getServerUrl()}/admin/customer/${customer_hid}/user/${user._id}/reset_pwd`,
                method: "get",
                withCredentials: true
            }, true, true);
            if (response.status === 200) {
                setNewPassword(response.data?.password);
            }
        } else {
            onClose();
        }
    }

    const handleClickCancel = () => {
        onClose();
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Nouveau mot de passe temporaire
                </DialogTitle>
                <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                    {!newPassword &&
                        <Typography>Un nouveau mot de passe temporaire va être généré pour l'utilisateur {user?.firstname} {user?.lastname}.</Typography>
                    }
                    {newPassword &&
                        <><Typography>Nouveau mot de passe: </Typography><Typography component="span" sx={{ fontWeight: 'bold' }}>{newPassword}</Typography></>
                    }
                </DialogContent>
                <DialogActions>
                    {!newPassword &&
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                    }
                    <Button
                        onClick={handleClickValidate}
                    >
                        {!newPassword ? "confirmer" : "fermer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



export { ResetPasswordDialog };