//*********************************************************************************************************************************************//
//                                                               IONIC imports                                                                 //
//*********************************************************************************************************************************************//
// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonInput, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
// import { eyeOutline, eyeOffOutline } from 'ionicons/icons';

//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useMatch, useLocation, useParams } from 'react-router-dom';


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useHttp } from "../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
// import { LoginModal } from "../components/LoginModal";
// import { IdleModal } from "../components/IdleModal";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
// import logo from "../img/val_logo.png";
import { getServerUrl } from '../config';
// import Establishment from '../models/Establishment';






const UserContext = createContext();

const UserProvider = ({ children }) => {

    const { setIsLoggedIn, isLoggedIn, httpRequest } = useHttp();
    const [user, setUser] = useState(undefined);
    const [establishments, setEstablishments] = useState(undefined);
    const [selectedEstablishment, setSelectedEstablishment] = useState(undefined);
    const [customer, setCustomer] = useState(undefined);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [loginFlag, setLoginFlag] = useState(true);
    const [lists, setLists] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const urlCustomer_hid = params.get('customer_hid');
    const urlEstablishment_id = params.get('establishment_id');


    //fetch user populated with its permissions
    const fetchUser = useCallback(async () => {
        setIsUserLoading(true);
        let response = await httpRequest({
            url: getServerUrl() + "/admin/admin_user/fetch",
            method: "get",
            withCredentials: true
        });
        if (response.status === 200) {
            setUser(response.data);
            console.log(response.data);
            setIsLoggedIn(true);
            setLoginFlag(false);
        }
        setIsUserLoading(false);
    }, [])
    const fetchLists = useCallback(async () => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/lists_by_keys`,
            data: [
                "ACTIVITY_LIST",
                "LICENSE_MODULES_LIST",
                "LICENSE_TYPES_LIST",
                "LICENSE_ACCESSES_LIST",
                "LICENSE_STATUSES_LIST",
                "LICENSE_STATUSES",
                "LICENSE_MODULE_VALUES",
                "ROLES_LIST",
                "MODULES_ROLES_VALUES",
                "JOB_GROUP_LIST",
                "JOB_LIST",
                "ORGANIZATION_LIST",
                "OBJECTIVE_PAQ_LIST",
                "REPONSE_TYPE_LIST",
                "NB_RESPONSE_LIST",
                "USER_TYPES_LIST",
                "USER_STATUSES_LIST",
                "OBSERVATION_GROUP_LIST",
                "THEMATIC_LIST",
                "ROLE_VALUES"
            ],
            method: "post",
            headers: { "Content-type": "Application/json" },
            withCredentials: true
        })
        if (response?.status === 200) {
            let parsedLists = JSON.parse(response.data);
            setLists(parsedLists);
            console.log(parsedLists);
        }
    }, [])

    useEffect(() => {
        fetchUser();
        fetchLists();
    }, []);

    const fetchCustomer = useCallback(async () => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/customer/${urlCustomer_hid}/fetch`,
            method: "get",
            withCredentials: true
        })
        if (response?.status === 200) {
            setCustomer(response.data.customer);
            console.log(response.data.customer);
        }
    }, [urlCustomer_hid])

    const fetchEstablishments = useCallback(async () => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/customer/${urlCustomer_hid}/establishment/fetch`,
            method: 'get',
            withCredentials: true
        });
        if (response.status === 200) {
            console.log(response.data);
            setEstablishments(response.data);
        }
    }, [urlCustomer_hid])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        if (!urlCustomer_hid) {
            setEstablishments(undefined);
            return;
        }
        fetchCustomer();
        fetchEstablishments();
    }, [urlCustomer_hid, user, isLoggedIn])

    useEffect(() => {
        if (!establishments) return;
        if (!urlEstablishment_id) {
            setSelectedEstablishment(undefined);
            return;
        }
        let est = establishments.find(e => e._id === urlEstablishment_id);
        if (est) setSelectedEstablishment(est);
    }, [urlEstablishment_id, establishments])

    const loginUser = () => {
        fetchUser();
        fetchLists();
    }

    const logoutUser = async () => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/logout`,
            method: "get",
            withCredentials: true
        })
        if (response.data) {
            setIsLoggedIn(false);
        }
    }

    const handleClickLogout = () => {
        setLoginFlag(true);
        logoutUser();
        //if the user clicks on disconnect button, then we do not preserve environment and set loginFlag to true -> user will be redirected to login page
    }

    return (
        <UserContext.Provider
            value={{
                user,
                loginUser,
                logoutUser,
                handleClickLogout,
                lists,
                isUserLoading,
                isLoggedIn,
                loginFlag,
                setEstablishments,
                establishments,
                setSelectedEstablishment,
                selectedEstablishment,
                customer,
                fetchEstablishments,
                fetchUser
            }}>
            <>
                {children}
                {/* {!isLoggedIn && loginFlag && !isUserLoading &&
                    <IonPage>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Connexion</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent fullscreen>
                            <IonImg src={logo} style={{ width: "auto", height: "25vh", marginLeft: "auto", marginRight: "auto", marginTop: "5vh", marginBottom: "5vh" }} />
                            <IonGrid>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="1"></IonCol>
                                    <IonCol size="auto" className="ion-padding-vertical">
                                        <IonInput
                                            placeholder="identifiant"
                                            className="input-base-style"
                                            clearinput="true"
                                            autofocus="true"
                                            onIonChange={e => { setUsername(e.detail.value) }}
                                        />
                                    </IonCol>
                                    <IonCol size="1"></IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-align-items-center">
                                    <IonCol size="1"></IonCol>
                                    <IonCol size="auto" className="ion-padding-vertical">
                                        <IonInput
                                            placeholder="mot de passe"
                                            className="input-base-style"
                                            clearinput="true"
                                            clearOnEdit="true"
                                            type={passwordVisible ? "text" : "password"}
                                            onIonChange={e => { setPassword(e.detail.value) }}
                                        />
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonButton fill="clear" onClick={() => {
                                            setPasswordVisible(!passwordVisible);
                                        }}><IonIcon icon={passwordVisible ? eyeOffOutline : eyeOutline} /></IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="auto">
                                        <IonButton
                                            className="button-base-style"
                                            onClick={() => { handleClickLogin() }}
                                        >
                                            se connecter
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                        </IonContent>
                    </IonPage>

                }

                <LoginModal
                    isOpen={!isLoggedIn && !isUserLoading && !loginFlag}
                    username={user?.username}
                />

                <IdleModal /> */}

            </>
        </UserContext.Provider >
    );
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };