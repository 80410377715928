//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VerifiedIcon from '@mui/icons-material/Verified';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { LicensesDialog } from "./LicensesDialog";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import License from "../../models/License";
import { getDateString } from "../../lib/lib";


//show list of all users that have any permission in the selected establishment, should it be validated or not

const Licenses = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, customer, lists, establishments } = useUser();
    const theme = useTheme();
    const { paths } = usePaths();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [licenses, setLicenses] = useState(undefined);
    const emptyLicensesDialogState = { isOpen: false, title: "", license: undefined };
    const [licensesDialogState, setLicensesDialogState] = useState(emptyLicensesDialogState);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const fetchLicenses = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: getServerUrl() + `/admin/customer/${customer_hid}/licenses/fetch`,
            headers: { "Content-type": "Application/json" },
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {
            const licensesTmp = response.data.map(l => new License(l));
            console.log(licensesTmp);
            setLicenses(licensesTmp);
        }
        setIsLoading(false);
    }, [])


    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchLicenses();
    }, [isLoggedIn, user])

    const getUserName = (user) => {
        if (!user) return "";
        return user.firstname + " " + user.lastname;
    }


    const columns = useMemo(() => [
        {
            id: 'establishment',
            accessorFn: (row) => row?.establishment?.name || "\u200B",
            header: 'Établissement',
            enableGrouping: true,
            filterVariant: "multi-select",
            filterSelectOptions: ["\u200B", ...(establishments || []).map(e => e?.name)],
            enableColumnFilterModes: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'module',
            accessorFn: (row) => lists?.LICENSE_MODULES_LIST?.find(e => e.value === row?.module)?.label,
            header: 'Module',
            enableGrouping: true,
            filterVariant: "multi-select",
            filterSelectOptions: lists?.LICENSE_MODULES_LIST?.map(e => e?.label),
            enableColumnFilterModes: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'type',
            accessorFn: (row) => lists?.LICENSE_TYPES_LIST?.find(e => e.value === row?.type)?.label,
            header: 'Type',
            enableGrouping: true,
            filterVariant: "multi-select",
            filterSelectOptions: lists?.LICENSE_TYPES_LIST?.map(e => e?.label),
            enableColumnFilterModes: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'access',
            accessorFn: (row) => lists?.LICENSE_ACCESSES_LIST?.find(e => e.value === row?.access)?.label,
            header: 'Accès',
            enableGrouping: true,
            filterVariant: "multi-select",
            filterSelectOptions: lists?.LICENSE_ACCESSES_LIST?.map(e => e?.label),
            enableColumnFilterModes: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'activation_date',
            accessorFn: (row) => getDateString(row?.activation_date),
            header: 'Activation',
            enableGrouping: true,
        },
        {
            id: 'expiration_date',
            accessorFn: (row) => getDateString(row?.expiration_date),
            header: 'Expiration',
            enableGrouping: true,
        },
        {
            id: 'status',
            accessorFn: (row) => lists?.LICENSE_STATUSES_LIST?.find(e => e.value === row?.status?.value)?.label,
            header: 'Statut',
            enableGrouping: true,
            filterVariant: "multi-select",
            filterSelectOptions: lists?.LICENSE_STATUSES_LIST?.map(e => e?.label),
            enableColumnFilterModes: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'user',
            accessorFn: (row) => getUserName(row?.user) || "libre",
            header: 'Affectation',
            enableGrouping: true,
        },
    ], [customer, establishments, lists]);

    const toolbar = (<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
            variant="outlined"
            onClick={() => setLicensesDialogState({
                isOpen: true,
                title: `Ajout licences`,
                license: new License({ clientAction: "new" })
            })}
        >AJOUT LICENCES</Button>
    </Box>);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MaterialReactTable
                    columns={columns}
                    data={licenses ? licenses : []}
                    enableColumnOrdering
                    enableGlobalFilter //Material React Table global filter will only work if empty string instead of undefined
                    enableGrouping
                    enableEditing
                    enablePinning
                    enableColumnResizing
                    editingMode="modal"
                    initialState={{ density: 'comfortable', showColumnFilters: true, columnPinning: { right: ["mrt-row-actions"] } }}
                    state={{ showSkeletons: licenses === undefined }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Interactions',
                            size: 120,
                            Cell: ({ row }) => (
                                <>
                                    <IconButton
                                        onClick={() => setLicensesDialogState({
                                            isOpen: true,
                                            title: `Modifier 1 licence`,
                                            license: new License({ ...row.original, clientAction: "modify" })
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    }}
                    muiTableBodyCellProps={{ align: "center" }}
                    renderEmptyRowsFallback={() => {
                        return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{licenses?.length > 0 ? 'Aucun résultat.' : 'Aucune licence.'}</em></h3></div>
                    }}
                    // enableDensityToggle={false}
                    localization={MRT_Localization_FR}
                    enablePagination={false}
                    enableRowVirtualization
                    rowVirtualizerProps={{
                        overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                        estimateSize: () => licenses?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                    }}
                    renderBottomToolbarCustomActions={({ table, row }) => toolbar}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            maxHeight: `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
                        }
                    })}
                />
            </Box>
            <LicensesDialog
                isOpen={licensesDialogState.isOpen}
                onClose={async () => {
                    setLicensesDialogState(emptyLicensesDialogState);
                    fetchLicenses();
                }}
                title={licensesDialogState.title}
                license={licensesDialogState.license}
            />
        </>
    );
};

export default Licenses;