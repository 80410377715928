import Address from "./Address";
import Contact from "./Contact";


class Customer {
    constructor(c) {
        this._id = c?._id;
        this.legal_entity = c?.legal_entity;
        this.human_id = c?.human_id;
        this.legal_person = c?.legal_person;
        this.tva_number = c?.tva_number;
        this.siret = c?.siret;
        this.capacity = c?.capacity;
        this.website = c?.website;
        this.billing_address = new Address(c?.billing_address);
        this.contact = new Contact(c?.contact);
        this.clientAction = c?.clientAction;
    }
}

export default Customer;