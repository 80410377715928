//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { forwardRef } from "react";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from "@mui/material/Stack";
import AlertTitle from '@mui/material/AlertTitle';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useIdleTimer } from 'react-idle-timer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import CONFIG from "../config/default";


function TransitionBottom(props) {
    return <Slide {...props} direction="up" />;
}

const Toast = ({ isOpen, severity, title, message, duration, handleClose }) => {
    //severity must be eight error, warning, info or success
    //isOpn, severity, message and handleClose are mandatory

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration || 5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            TransitionComponent={TransitionBottom}
            onClose={handleClose}
        >
            <Alert elevation={6} variant="filled" severity={severity} onClose={handleClose} sx={{ width: '50vw', minWidth: "400px", '& .MuiAlert-message': { textAlign: "center", width: "100%" } }}>
                <AlertTitle>{title || ""}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );

}

export { Toast }