import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from "./UserProvider";


const PathsContext = createContext();

const PathsProvider = ({ children }) => {
    const location = useLocation();
    const { customer, selectedEstablishment } = useUser();

    const paths = {
        login: {
            router: "/login",
            app: () => "/login",
            title: "Connexion"
        },
        customers: {
            router: "/app/customers",
            app: () => "/app/customers",
            title: "Clients"
        },
        customerUsers: {
            router: "/app/admin/users",
            app: ({ customer_hid }) => `/app/admin/users?customer_hid=${customer_hid}`,
            title: `${customer?.human_id} Utilisateurs`
        },
        establishments: {
            router: "/app/establishments",
            app: ({ customer_hid }) => `/app/establishments?customer_hid=${customer_hid}`,
            title: `${customer?.human_id || ''} Établissements`
        },
        // establishmentUsers: {
        //     router: "/app/admin/users",
        //     app: ({ customer_hid, establishment_id }) => `/app/admin/users?customer_hid=${customer_hid}&&establishment_id=${establishment_id}`,
        //     title: `${selectedEstablishment?.human_id} Liste utilisateurs`
        // },
        licenses: {
            router: "/app/admin/licenses",
            app: ({ customer_hid }) => `/app/admin/licenses?customer_hid=${customer_hid}`,
            title: `${customer?.human_id || ''} Licences`
        },
        referentialReleased: {
            router: "/app/referential/released",
            app: () => `/app/referential/released`,
            title: "Référentiel publié",
            menu: "Publié"
        },
        referentialDraft: {
            router: "/app/referential/draft",
            app: () => `/app/referential/draft`,
            title: "Référentiel brouillon",
            menu: "Brouillon"
        },
        referentialArchived: {
            router: "/app/referential/archived",
            app: () => `/app/referential/archived`,
            title: "Référentiel(s) archivé(s)",
            menu: "Archivé(s)"
        },
        logs: {
            router: "/app/logs",
            app: () => `/app/logs`,
            title: "Logs",
            menu: "Logs"
        }
    }

    //returns current path object. Example: for "/login", returns login = {router: "/login", app: () => "/login", title: "Connexion"}
    const getCurrentPathObject = () => {
        if (location?.pathname) {
            const splittedLocation = location.pathname.split("/");
            for (let pathname in paths) {
                const splittedPath = paths[pathname].router.split("/");
                if (splittedLocation.length !== splittedPath.length) continue;
                for (let i = 0; i < splittedLocation.length; i++) {
                    if (splittedPath[i].startsWith(":")) {
                        if ((i === splittedLocation.length - 1)) return paths[pathname];
                        continue;
                    }
                    if (splittedPath[i] !== splittedLocation[i]) break;
                    if (i === splittedLocation.length - 1) return paths[pathname];
                }
            }
        }
        return {};
    }

    return (
        <PathsContext.Provider
            value={{
                paths,
                getCurrentPathObject
            }}>
            <>
                {children}
            </>
        </PathsContext.Provider>
    );
}

const usePaths = () => useContext(PathsContext);

export { PathsProvider, usePaths };