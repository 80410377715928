import { useLayoutEffect, useEffect, useRef } from 'react';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';


const modules = {
    //pay attention to the order of tooltips if toolbar oder is updated
    toolbar: [
        ['bold', 'italic', 'underline'],  // toggled buttons
        [{ 'indent': '-1' }, { 'indent': '+1' }],  // indent and outdent
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // lists
        ['link']  // link button for creating hyperlinks
    ],
};

const formats = [
    'bold', 'italic', 'underline',
    'indent',
    'list', 'bullet',
    'link'
    // 'image' and 'video' are omitted to disallow images and videos
];

function RichTextEditor({ value, onChange, ...otherProps }) {
    const editorRef = useRef(null);

    useLayoutEffect(() => {
        if (editorRef.current) {
            //setup tooltips, pay attention to the order of tooltips if toolbar oder is updated
            const buttons = editorRef.current.querySelectorAll('.ql-toolbar button');
            const tooltips = [
                'Gras', 'Italique', 'Souligné',
                'Diminuer le retrait', 'Augmenter le retrait',
                'Numérotation', 'Puces',
                'Insérer lien'
            ];
            buttons.forEach((button, index) => {
                button.setAttribute('title', tooltips[index]);
            });
        }
    }, []);

    return (
        <div ref={editorRef} style={{ width: "100%" }}>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={onChange}
                {...otherProps}
            />
        </div>
    );
}

const getEmptyDelta = () => {
    return { ops: [] };
}

function isEmptyDelta(delta) {
    if (!delta?.ops) return true;
    if (delta.ops.length === 0) return true;
    // Check if every operation is insert with only whitespace or a newline
    return delta.ops.every(op => {
        // If there's an insert op, check if it's just whitespace or a newline
        if (op.insert) {
            // Remove whitespace from the start and end, and check if it's just a newline
            return op.insert.trim() === '' || op.insert.trim() === '\n';
        }
        // Ops that aren't inserts (like retain and delete) don't add content
        return true;
    });
}

export { RichTextEditor, getEmptyDelta, isEmptyDelta };