//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useRef } from "react";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from "@mui/material/Modal";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";
import { useToast } from "../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import EqpLogo from "../img/logo_only.png";
import EqpLogoText from "../img/logo_title.png";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../config";


const StyledModal = styled(Modal, {
    shouldForwardProp: (prop) => prop !== 'slotProps',
})(({ slotProps }) => ({
    ...slotProps,
    '& .MuiBackdrop-root': {
        backdropFilter: 'blur(6px)',
    },
}));

const LoginModal = ({ isOpen }) => {

    //providers
    const { httpRequest } = useHttp();
    const { loginUser, user } = useUser();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { presentToast } = useToast();

    return (
        <div>
            <StyledModal
                open={isOpen}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 400,
                    minHeight: 400,
                    width: "auto",
                    height: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowY: "auto"
                }}>
                    <AppBar>
                        <Toolbar sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
                                <Typography variant="h6">
                                    Connexion
                                </Typography>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box
                        component="form"
                        sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                        onSubmit={handleSubmit(async (data) => {
                            let response = await httpRequest({
                                url: `${getServerUrl()}/admin/login`,
                                method: "post",
                                data: { username: user.username, password: data.password },
                                headers: { "Content-Type": "application/json" },
                                withCredentials: true
                            })
                            if (response?.status === 200) {
                                loginUser();
                            } else if (response?.status === 401) {
                                presentToast({
                                    message: "Mot de passe invalide",
                                    severity: "error"
                                })
                            }
                        })}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", gap: "12px", marginTop: "48px" }}>
                            <img src={EqpLogo} alt="Logo EQP" style={{ height: "11vh", minHeight: "120px", width: "auto" }} />
                            <img src={EqpLogoText} alt="Text Logo EQP" style={{ height: "3vh", minHeight: "30px", width: "auto" }} />
                        </Box>
                        <TextField
                            label="Mot de passe"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            error={errors.password ? true : false}
                            helperText={errors.password?.message}
                            sx={{ width: "300px", margin: "0 auto 2% auto" }}
                            {...register("password", { required: "Mot de passe requis" })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{ width: "200px", margin: "0 auto 0 auto" }}
                            type="submit"
                        >
                            se reconnecter
                        </Button>
                    </Box>
                </Box>
            </StyledModal>
        </div>
    );
}

export { LoginModal };