import { immerable, current, isDraft } from "immer";
import { compareObjectKeyValuePairs, compareObjects } from "../lib/lib";


function Observation(o) {
    this.question = o?.question;
    this.rbpp = o?.rbpp;
    this.requested_response = o?.requested_response;
    this.nb_responses = o?.nb_responses;
    this.concerned_activities = o?.concerned_activities;
    this.is_archived = o?.is_archived || false;
    this.immutable_id = o?.immutable_id;
    this.group = o?.group;
    this.isOpen = true;
    this.hidden = false;
    this.clientAction = o?.clientAction;
    this[immerable] = true;
}

Observation.prototype.archive = function () {
    this.is_archived = true;
}

Observation.prototype.unarchive = function () {
    this.is_archived = false;
}

Observation.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}

Observation.rules = {
    question: {
        required: "Question requise",
        minLength: {
            value: 6,
            message: "6 caractères minimum"
        }
    },
    rbpp: {},
    requested_response: {
        required: "Type de réponse requis"
    },
    nb_responses: {
        required: "Nombre de réponses requis"
    },
    concerned_activities: {
        required: "Au moins une activité requise"
    },
    group: {
        required: "Groupe requis"
    }
}

function Criterion(c) {
    this.name = c?.name;
    this.job_groups = c?.job_groups;
    this.important = c?.important;
    this.objectivePaqMatch = c?.objectivePaqMatch || [];
    this.is_archived = c?.is_archived || false;
    this.immutable_id = c?.immutable_id;
    this.observations = [];
    this.isOpen = true;
    this.hidden = false;
    this.clientAction = c?.clientAction;
    if (c?.observations?.length > 0) {
        for (let i = 0; i < c.observations.length; i++) {
            this.observations[i] = new Observation(c.observations[i]);
        }
    }
    this[immerable] = true;
}

Criterion.prototype.archive = function () {
    for (let observation of this.observations) {
        observation.archive();
    }
    this.is_archived = true;
}

Criterion.prototype.unarchive = function () {
    this.is_archived = false;
}

Criterion.prototype.deleteObservation = function (index) {
    if (this.observations[index]) {
        this.observations.splice(index, 1);
    }
}

Criterion.prototype.shiftObservationUp = function (index) {
    if (this.observations[index] && index > 0) {
        [this.observations[index], this.observations[index - 1]] = [this.observations[index - 1], this.observations[index]];
    }
}

Criterion.prototype.shiftObservationDown = function (index) {
    if (this.observations[index] && index < this.observations?.length) {
        [this.observations[index], this.observations[index + 1]] = [this.observations[index + 1], this.observations[index]];
    }
}

Criterion.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}

//validation rules for react-hook-form
Criterion.rules = {
    name: {
        required: "Champs requis",
        minLength: {
            value: 6,
            message: "6 caractères minimum"
        }
    },
    job_groups: {
        required: {
            value: true,
            message: "Au moins un groupe métier requis"
        }
    },
    objectivePaqMatch: {
        required: {
            value: true,
            message: "Au moins un objectif requis"
        }
    }
}

function Objective(o) {
    this.name = o?.name;
    this.thematic = o?.thematic;
    this.is_archived = o?.is_archived || false;
    this.immutable_id = o?.immutable_id;
    this.criteria = [];
    this.isOpen = true;
    this.hidden = false;
    this.clientAction = o?.clientAction;
    if (o?.criteria?.length > 0) {
        for (let i = 0; i < o.criteria.length; i++) {
            this.criteria[i] = new Criterion(o.criteria[i]);
        }
    }
    this[immerable] = true;
}

Objective.prototype.archive = function () {
    for (let criterion of this.criteria) {
        criterion.archive();
    }
    this.is_archived = true;
}

Objective.prototype.unarchive = function () {
    this.is_archived = false;
}

Objective.prototype.deleteCriterion = function (index) {
    if (this.criteria[index]) {
        this.criteria.splice(index, 1);
    }
}

Objective.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}

Objective.prototype.shiftCriterionUp = function (index) {
    if (this.criteria[index] && index > 0) {
        [this.criteria[index], this.criteria[index - 1]] = [this.criteria[index - 1], this.criteria[index]];
    }
}

Objective.prototype.shiftCriterionDown = function (index) {
    if (this.criteria[index] && index < this.criteria?.length) {
        [this.criteria[index], this.criteria[index + 1]] = [this.criteria[index + 1], this.criteria[index]];
    }
}


//validation rules for react-hook-form
Objective.rules = {
    name: {
        required: "Champs requis",
        minLength: {
            value: 6,
            message: "6 caractères minimum"
        }
    },
    thematic: {
        required: "Thématique requise"
    }
}

function Chapter(c) {
    this.name = c?.name;
    this.is_archived = c?.is_archived || false; //archive elements instead of delete
    this.immutable_id = c?.immutable_id;
    this.objectives = [];
    this.isOpen = true; //true to see nested children
    this.hidden = false; //if filtered for example
    this.clientAction = c?.clientAction; //it's a new object if clientAction = "new"
    if (c?.objectives?.length > 0) {
        for (let i = 0; i < c.objectives.length; i++) {
            this.objectives[i] = new Objective(c.objectives[i], this);
        }
    }
    this[immerable] = true;
}
Chapter.prototype.archive = function () {
    for (let objective of this.objectives) {
        objective.archive();
    }
    this.is_archived = true;
}

Chapter.prototype.unarchive = function () {
    this.is_archived = false;
}

Chapter.prototype.deleteObjective = function (index) {
    if (this.objectives[index]) {
        this.objectives.splice(index, 1);
    }
}

Chapter.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}

Chapter.rules = {
    name: {
        required: "Champs requis",
        minLength: {
            value: 6,
            message: "6 caractères minimum"
        }
    }
}


function Referential(r) {
    this.immutable_id = r?.immutable_id;
    this.is_released = {
        value: r?.is_released?.value,
        by: r?.is_released?.by,
        on: r?.is_released?.on
    }
    this.is_archived = {
        value: r?.is_archived?.value,
        by: r?.is_archived?.by,
        on: r?.is_archived?.on
    }
    this.created = {
        by: r?.created?.by,
        on: r?.created?.on
    }
    this.imported_from_ref_immutable_id = r?.imported_from_ref_immutable_id;
    this.version = r?.version;
    this.HAS_date = r?.HAS_date;
    this._id = r?._id;
    this.expandAll = true;
    this.showArchived = false;
    this.chapters = [];
    if (r?.chapters?.length > 0) {
        for (let i = 0; i < r.chapters.length; i++) {
            this.chapters[i] = new Chapter(r.chapters[i]);
        }
    }
    this[immerable] = true;
    //create a backup so we can check if referential has been modified by user

    this.backup = r?.backup || { chapters: this.chapters };
    this.is_modified = this.isModified(r?.backup);
    this.contain_empty_parents = this.containEmptyParents(r?.backup?.chapters);
}

Referential.prototype.deleteChapter = function (index) {
    if (this.chapters[index]) {
        this.chapters.splice(index, 1);
    }
}

Referential.prototype.filter = function (searchFilter, activityFilter) {
    //filter on observations only, and hide all empty parents
    let isSearchFilter = searchFilter?.current !== "";
    let searchFilterTmp = searchFilter?.current?.toLowerCase();
    let isActivityFilter = activityFilter?.current?.length > 0;
    for (let chapter of this.chapters) {
        chapter.hidden = "poueeeeet"; //just add anything here to force rerender in case both filters are empty since filters are react refs
        chapter.hidden = (isActivityFilter || isSearchFilter);
        for (let objective of chapter.objectives) {
            objective.hidden = (isActivityFilter || isSearchFilter);
            for (let criterion of objective.criteria) {
                criterion.hidden = (isActivityFilter || isSearchFilter);
                for (let observation of criterion.observations) {
                    observation.hidden = (isActivityFilter || isSearchFilter);
                    let activityFiltered = isActivityFilter && !observation.concerned_activities.find(e => activityFilter?.current.includes(e));
                    let searchFiltered = isSearchFilter && !observation.question.toLowerCase().includes(searchFilterTmp)
                    if (!activityFiltered && !searchFiltered) {
                        observation.hidden = false;
                        criterion.hidden = false;
                        objective.hidden = false;
                        chapter.hidden = false;
                    }
                }
            }
        }
    }
}

Referential.prototype.showArchivedElements = function () {
    //shows or hide archived elements depending on showArchived value
    this.showArchived = !this.showArchived;
}

Referential.prototype.expand = function () {
    //expand or collapse referential depending on expandAll value
    this.expandAll = !this.expandAll;
    for (let chapter of this.chapters) {
        chapter.isOpen = this.expandAll;
        for (let objective of chapter.objectives) {
            objective.isOpen = this.expandAll;
            for (let criterion of objective.criteria) {
                criterion.isOpen = this.expandAll;
                for (let observation of criterion.observations) {
                    observation.isOpen = this.expandAll;
                }
            }
        }
    }
}


Referential.prototype.containEmptyParents = function (chapters = undefined) {
    //returns true if at least one parent is empty, false otherwise
    const chaptersTmp = chapters || this?.chapters;
    for (let chapter of chaptersTmp) {
        if (chapter?.objectives?.length === 0) {
            return true;
        }
        for (let objective of chapter?.objectives) {
            if (objective?.criteria?.length === 0) {
                return true;
            }
            for (let criterion of objective?.criteria) {
                if (criterion?.observations?.length === 0) {
                    return true;
                }
            }
        }
    }
    return false;
}

Referential.prototype.isEmpty = function () {
    //returns true if chapters is empty, false otherwise
    return this?.chapters?.length === 0;
}


Referential.prototype.isModified = function (backup = undefined) {

    //isModified can be called either with a plain js object, or with an immer proxy. 
    //but even with proxy, below comparison works just fine.
    let backupTmp = backup || this.backup;
    const ref = this;

    let keysToIgnore = [
        "*.immutable_id",
        "*.isOpen",
        "*.hidden",
        "*.clientAction",
        "*.is_archived"
    ]

    if (ref?.chapters?.length !== backupTmp?.chapters?.length) {
        return true;
    }

    if (!compareObjects({ obj1: ref?.chapters, obj2: backupTmp?.chapters, keysToIgnore: keysToIgnore, falsyEquals: true, checkOrderInArrays: true, includeObj2Keys: true })) {
        return true;
    }

    return false;

}

export { Referential, Chapter, Objective, Criterion, Observation };