import { HttpProvider } from './HttpProvider';
import { UserProvider } from './UserProvider';
import { PathsProvider } from './PathsProvider';
import { ToastProvider } from './ToastProvider';
import { DialogProvider } from './DialogProvider';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';
import { MfaProvider } from './MfaProvider';

function AppProviders({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <ToastProvider>
                <DialogProvider>
                    <HttpProvider>
                        <UserProvider>
                            <MfaProvider>
                                <PathsProvider>
                                    {children}
                                </PathsProvider>
                            </MfaProvider>
                        </UserProvider>
                    </HttpProvider>
                </DialogProvider>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default AppProviders;