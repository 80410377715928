class Contact {
    constructor(c) {
        this.firstname = c?.firstname;
        this.lastname = c?.lastname;
        this.job = c?.job;
        this.tel = c?.tel;
        this.email = c?.email;
        this.fax = c?.fax;
    }
}

export default Contact;