import { Navigate, Route, Routes } from "react-router-dom";
import { IdleModal } from "./components/IdleModal";
import { LoginModal } from "./components/LoginModal";
import { AppToolbar } from "./components/Toolbar";
import { usePaths } from "./context/PathsProvider";
import { useUser } from "./context/UserProvider";
import Login from "./pages/Login";
import Customers from "./pages/customers/Customers";
import Licenses from "./pages/licenses/Licenses";
import Establishments from "./pages/establishments/Establishments";
import ReferentialAdmin from "./pages/referentialAdmin/ReferentialAdmin";
import CustomerUsers from "./pages/customerUsers/CustomerUsers";
import "./theme/global.css";
import Logs from "./pages/logs/Logs";



const RequireAuth = ({ children }) => {
    const { isUserLoading, isLoggedIn, loginFlag } = useUser();
    const { paths } = usePaths();

    if (isUserLoading && !isLoggedIn && loginFlag) {
        return <></>;
    }
    if (!isLoggedIn) {
        //if user has never logged in from login page and has no environment to preserve
        if (loginFlag) {
            return <Navigate to={paths.login.app()} />;
        }
        //return backdrop et modal et children
        return <>
            <LoginModal isOpen={!isLoggedIn && !isUserLoading && !loginFlag} />
            {children}
        </>
    }
    return <>
        <IdleModal />
        {children}
    </>;
}


function App() {
    const { paths } = usePaths();
    return (
        <Routes>
            {/* <Route path={"/create_admin"} element={<CreateAdmin />} /> */}
            <Route path={paths.login.router} element={<Login />} />
            <Route path="/" element={<Navigate to={paths.customers.app()} />} />
            <Route path="/app" element={<RequireAuth><AppToolbar /></RequireAuth>}>
                <Route path={paths.customers.router} element={<Customers />} />
                <Route path={paths.licenses.router} element={<Licenses />} />
                <Route path={paths.establishments.router} element={<Establishments />} />
                <Route path={paths.customerUsers.router} element={<CustomerUsers />} />
                <Route path={paths.referentialReleased.router} element={<ReferentialAdmin status="released" />} />
                <Route path={paths.referentialDraft.router} element={<ReferentialAdmin status="draft" />} />
                <Route path={paths.referentialArchived.router} element={<ReferentialAdmin status="archived" />} />
                <Route path={paths.logs.router} element={<Logs />} />
            </Route>
        </Routes>
    );
}

export default App;
