//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//

import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import PasswordIcon from '@mui/icons-material/Password';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useDialog } from "../../context/DialogProvider";
import { useMfa } from "../../context/MfaProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { ResetPasswordDialog } from "./ResetPasswordDialog";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { User, Role } from "../../models/User";
import { CustomerUsersDialog } from "./CustomerUsersDialog";
import { UserPermissionsDialog } from "./UserPermissionsDialog";
import { compareObjects } from "../../lib/lib";
import { Access } from "../../models/Permission";


const CustomerUsers = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, establishments, lists, customer } = useUser();
    const { requestEmailMfa } = useMfa();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [customerUsers, setCustomerUsers] = useState([]);
    const emptyCustomerUsersDialogState = { isOpen: false, title: "", user: undefined };
    const [customerUsersDialogState, setCustomerUsersDialogState] = useState(emptyCustomerUsersDialogState);
    const emptyUserPermissionsDialogState = { isOpen: false, title: "", permGroups: undefined, CADMPermission: undefined };
    const [userPermissionsDialogState, setUserPermissionsDialogState] = useState(emptyUserPermissionsDialogState);
    const emptyResetPasswordDialog = { isOpen: false, user: undefined };
    const [resetPasswordDialog, setResetPasswordDialog] = useState(emptyResetPasswordDialog);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const fetchusers = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/customer/${customer_hid}/user/fetch`,
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {
            console.log(response.data);
            setCustomerUsers(response.data.users);
        }
        setIsLoading(false);
    }, [customer_hid])

    const fetchUserPermissions = async (user_id) => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/customer/${customer_hid}/user/${user_id}/permissions/fetch`,
            method: 'get',
            showLoading: true,
            withCredentials: true
        });

        if (response.status === 200) {
            console.log(response.data);
            let permissions = response.data;

            //if permissions has a permission for module CADM, separate it from the others
            let CADMIndex = permissions.findIndex(e => e.module === lists.LICENSE_MODULE_VALUES["CADM"]);
            let CADMPermission = new Access({ module: lists.LICENSE_MODULE_VALUES["CADM"] });
            if (CADMIndex !== -1) {
                CADMPermission = new Access({ ...permissions[CADMIndex] });
                permissions.splice(CADMIndex, 1);
            }

            //then map permissions by est_id
            let permByEst = {};
            for (let perm of permissions) {
                if (permByEst[perm?.establishment_id]) {
                    permByEst[perm?.establishment_id].push(perm);
                } else {
                    permByEst[perm?.establishment_id] = [perm];
                }
            }

            //now for each est_id, group identical permissions in an array
            let permGroups = [];
            for (let est_id of Object.keys(permByEst)) {
                let foundValue = false;
                for (let permGroup of permGroups) {
                    if (compareObjects({ obj1: permGroup, obj2: permByEst[est_id], keysToIgnore: ["establishment_id"] })) {
                        foundValue = true;
                        permGroup.establishment_ids.push(est_id);
                        break;
                    }
                }
                if (!foundValue) {
                    //remove establishment_id from permissions objects
                    let permsWithoutEst_id = permByEst[est_id].map(e => new Access(e));
                    permGroups.push({ permissions: permsWithoutEst_id, establishment_ids: [est_id] });
                }
            }

            return { CADMPermission, permGroups };
        }
    }

    useEffect(() => {
        if (!isLoggedIn || !user || !customer_hid) return;
        fetchusers();
    }, [isLoggedIn, user, customer_hid])




    const columns = useMemo(() => [
        {
            accessorKey: 'firstname',
            header: 'Prénom',
            enableGrouping: false,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            accessorKey: 'lastname',
            header: 'Nom',
            enableGrouping: false,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'job',
            accessorFn: (row) => lists?.JOB_LIST?.find(e => e.value === row?.job)?.label || "",
            header: 'Poste',
            filterVariant: "multi-select",
            filterSelectOptions: lists?.JOB_LIST?.map(e => e?.label) || [],
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'type.value',
            accessorFn: (row) => {
                return lists?.USER_TYPES_LIST?.find(e => e.value === row.type.value)?.label
            },
            header: 'Type',
            filterVariant: "multi-select",
            filterSelectOptions: lists?.USER_TYPES_LIST?.map(e => e?.label),
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'status.value',
            accessorFn: (row) => {
                return lists?.USER_STATUSES_LIST?.find(e => e.value === row.status.value)?.label
            },
            header: 'Status',
            filterVariant: "multi-select",
            filterSelectOptions: lists?.USER_STATUSES_LIST?.map(e => e?.label),
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'job_groups.value',
            accessorFn: (row) => {
                let string = "";
                for (let [index, jg] of row.job_groups.value.entries()) {
                    string += lists?.JOB_GROUP_LIST?.find(e => e.value === jg)?.label;
                    if (!index !== row.job_groups.value.length - 1) {
                        string += ", ";
                    }
                }
                return string;
            },
            header: 'Groupes métiers',
            filterVariant: "multi-select",
            filterSelectOptions: lists?.JOB_GROUP_LIST ? lists?.JOB_GROUP_LIST?.map(e => e.label) : [],
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'organization',
            accessorFn: (row) => {
                return lists?.ORGANIZATION_LIST?.find(e => e.value === row.organization)?.label
            },
            header: 'Organisme',
            filterVariant: "multi-select",
            filterSelectOptions: lists?.ORGANIZATION_LIST ? lists?.ORGANIZATION_LIST?.map(e => e.label) : [],
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'establishments',
            accessorFn: (row) => {
                let est_ids = [...new Set(row?.authorizations?.filter(e => e?.module !== lists?.LICENSE_MODULE_VALUES["CADM"])?.map(e => e.establishment_id))];
                let est_names = est_ids?.map(est_id => establishments?.find(est => est._id === est_id)?.name);
                return est_names.join(", ");
            },
            header: 'Établissements',
            filterVariant: "multi-select",
            filterSelectOptions: establishments?.map(e => e?.name),
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
    ], [customerUsers, establishments, lists]);

    const toolbar = (<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
            variant="outlined"
            onClick={() => setCustomerUsersDialogState({
                isOpen: true,
                user: new User({
                    clientAction: "new",
                    customer_id: customer._id
                }),
                title: `Nouvel utilisateur client "${customer?.legal_entity}"`
            })}
        >NOUVEL UTILISATEUR</Button>
    </Box>);

    const onResetPasswordClick = (row) => {
        setResetPasswordDialog({ isOpen: true, user: row?.original });
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MaterialReactTable
                    columns={columns}
                    data={customerUsers ? customerUsers : []}
                    enableColumnOrdering
                    enableGlobalFilter //Material React Table global filter will only work if empty string instead of undefined
                    enableGrouping
                    enableEditing
                    enablePinning
                    enableColumnResizing
                    enableColumnFilterModes
                    editingMode="modal"
                    initialState={{ density: 'comfortable', showColumnFilters: true, columnPinning: { right: ["mrt-row-actions"] } }}
                    state={{ showSkeletons: isLoading }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Editer', //change "Actions" to "Editer"
                            Cell: ({ row }) => (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
                                    <Tooltip title="Modifier les permissions de l'utilisateur" disableInteractive>
                                        <IconButton
                                            onClick={async () => {
                                                let tmp = await fetchUserPermissions(row?.original?._id);
                                                if (tmp?.CADMPermission && tmp?.permGroups) {
                                                    setUserPermissionsDialogState({
                                                        isOpen: true,
                                                        permGroups: tmp.permGroups,
                                                        CADMPermission: tmp.CADMPermission,
                                                        user: row?.original,
                                                        title: `Modifier permissions ${row?.original?.firstname} ${row?.original?.lastname}`
                                                    })
                                                }
                                            }}
                                        >
                                            <KeyIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Modifier l'utilisateur" disableInteractive>
                                        <IconButton
                                            onClick={() => setCustomerUsersDialogState({
                                                isOpen: true,
                                                user: new User({ ...row?.original, clientAction: "modify" }),
                                                title: `Modifier utilisateur ${row?.original?.firstname} ${row?.original?.lastname}`
                                            })}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Réinitialiser mot de passe de l'utilisateur" disableInteractive>
                                        <IconButton
                                            onClick={() => requestEmailMfa(() => onResetPasswordClick(row))}
                                        >
                                            <PasswordIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )
                        }
                    }}
                    muiTableBodyCellProps={{ align: "center" }}
                    renderEmptyRowsFallback={() => {
                        return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{customerUsers?.length > 0 ? 'Aucun résultat.' : 'Aucun utilisateur.'}</em></h3></div>
                    }}
                    // enableDensityToggle={false}
                    localization={MRT_Localization_FR}
                    enablePagination={false}
                    enableRowVirtualization
                    rowVirtualizerProps={{
                        overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                        estimateSize: () => customerUsers?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                    }}
                    renderBottomToolbarCustomActions={({ table, row }) => toolbar}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            maxHeight: `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
                        }
                    })}
                />
            </Box>
            <CustomerUsersDialog
                isOpen={customerUsersDialogState.isOpen}
                onClose={async () => {
                    setCustomerUsersDialogState(emptyCustomerUsersDialogState);
                    fetchusers();
                }}
                title={customerUsersDialogState.title}
                user={customerUsersDialogState.user}
            />
            <UserPermissionsDialog
                isOpen={userPermissionsDialogState.isOpen}
                onClose={async () => {
                    setUserPermissionsDialogState(emptyUserPermissionsDialogState);
                    fetchusers();
                }}
                title={userPermissionsDialogState.title}
                user={userPermissionsDialogState.user}
                permGroups={userPermissionsDialogState.permGroups}
                CADMPermission={userPermissionsDialogState.CADMPermission}
            />
            <ResetPasswordDialog
                isOpen={resetPasswordDialog.isOpen}
                onClose={async () => {
                    setResetPasswordDialog(emptyResetPasswordDialog);
                    fetchusers();
                }}
                user={resetPasswordDialog.user}
            />
        </>
    );
};

export default CustomerUsers;
