//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { compareObjects } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Access } from "../../models/Permission";


const UserPermissionRenderer = ({ parentIndex, userHasEmail }) => {

    const theme = useTheme();
    const { lists, establishments } = useUser();
    const { control, setValue, formState: { errors } } = useFormContext();

    const LICENSE_MODULES_LIST_WITHOUT_CADM = lists?.LICENSE_MODULES_LIST?.filter(e => e.value !== "CADM");

    //hook to handle dynamic fields for strengths
    const permissionsFieldsManager = useFieldArray({
        name: `permGroups[${parentIndex}].permissions`,
        control
    });

    //useWatch on permissions to adapt rôle options and validations
    let permissionsWatch = useWatch({
        control,
        name: `permGroups[${parentIndex}].permissions`,
    });

    //useWatch on establishments to dynamically filter their acceptable values
    let permGroupsWatch = useWatch({
        control,
        name: `permGroups`,
    });

    return (
        <Stack direction="column" spacing={2}>
            <Controller
                name={`permGroups[${parentIndex}].establishment_ids`}
                rules={{ required: "Établissement requis" }}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    const { onChange, value, ref } = field;

                    //filter establishment options deoending on other establishment values
                    //an establishment can only be chosen ONCE
                    let establishmentOptions = [];
                    if (permGroupsWatch) {
                        let otherpermGroups = [
                            ...permGroupsWatch?.slice(0, parentIndex),
                            ...permGroupsWatch?.slice(parentIndex + 1)
                        ];
                        establishmentOptions = establishments?.map(e => e._id).filter(e => {
                            return !otherpermGroups?.find(p => p?.establishment_ids?.includes(e));
                        });
                    }
                    return (
                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                            <AutocompleteWithAllOption
                                options={establishmentOptions}
                                getOptionLabel={(option) => {
                                    return establishments.find(e => e._id === option)?.name
                                }}
                                value={value || []}
                                onChange={onChange}
                                ref={ref}
                                label="Établissement(s)"
                            />
                            <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                    )
                }}
            />
            {permissionsFieldsManager?.fields?.map((field, index) => {
                return (
                    <Box key={field.id} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Box sx={{ width: "100%" }}>
                            <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
                                <Controller
                                    name={`permGroups[${parentIndex}].permissions[${index}].module`}
                                    rules={{
                                        required: "Module requis",
                                    }}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => {
                                        const { onChange, value, ref } = field;
                                        //filter moduleOptions
                                        let moduleOptions = LICENSE_MODULES_LIST_WITHOUT_CADM?.filter(e => {
                                            if (!permGroupsWatch) return false;
                                            let otherPermissions = [
                                                ...permGroupsWatch[parentIndex].permissions?.slice(0, index),
                                                ...permGroupsWatch[parentIndex].permissions?.slice(index + 1)
                                            ];
                                            return !otherPermissions?.find(op => op.module === e.value);
                                        });

                                        return (
                                            <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                <Autocomplete
                                                    options={moduleOptions}
                                                    value={moduleOptions?.find(e => e.value === value) || null}
                                                    isOptionEqualToValue={(option, value) => {
                                                        return option?.value === value.value
                                                    }}
                                                    getOptionLabel={option => {
                                                        return option.label;
                                                    }}
                                                    onChange={(event, selectedOption) => {
                                                        onChange(selectedOption?.value || null)
                                                        setValue(`permGroups[${parentIndex}].permissions[${index}].role`, null);
                                                    }}
                                                    renderInput={(params) => {
                                                        return <TextField {...params} label="Module" variant="standard" inputRef={ref} />
                                                    }}
                                                />
                                                <FormHelperText>{error?.message}</FormHelperText>
                                            </FormControl>
                                        )
                                    }}
                                />
                                <Controller
                                    name={`permGroups[${parentIndex}].permissions[${index}].role`}
                                    rules={{
                                        required: "Rôle requis",
                                        validate: (value, formValues) => {
                                            //validate that user has an email if module is est admin and role is editor (in such case email is mandatory)
                                            //if module is not EADM return true
                                            if (formValues?.permGroups?.[parentIndex]?.permissions?.[index]?.module !== lists?.LICENSE_MODULE_VALUES["EADM"]) {
                                                return true;
                                            }
                                            //if role is RDR return true
                                            else if (value === lists?.ROLE_VALUES["RDR"]) {
                                                return true;
                                            }
                                            //if role is EDT return true is user has an email
                                            else if (userHasEmail) {
                                                return true;
                                            }
                                            //in all other cases, return false
                                            return "L'utilisateur doit avoir un email pour choisir ce rôle";
                                        }
                                    }}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => {
                                        const { onChange, value, ref } = field;

                                        //filter roleOptions
                                        let rolesOptions = [];
                                        if (permGroupsWatch) {
                                            let allowedRoles = lists?.MODULES_ROLES_VALUES?.find(e => e.module === permGroupsWatch[parentIndex]?.permissions[index]?.module)?.roles;
                                            rolesOptions = lists?.ROLES_LIST?.filter(e => allowedRoles?.includes(e.value));
                                        }

                                        return (
                                            <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                <Autocomplete
                                                    options={rolesOptions}
                                                    value={rolesOptions?.find(e => e.value === value) || null}
                                                    isOptionEqualToValue={(option, value) => {
                                                        return option?.value === value.value
                                                    }}
                                                    getOptionLabel={option => {
                                                        return option.label;
                                                    }}
                                                    onChange={(event, selectedOption) => {
                                                        onChange(selectedOption?.value || null)
                                                    }}
                                                    renderInput={(params) => {
                                                        return <TextField {...params} label="Role" variant="standard" inputRef={ref} />
                                                    }}
                                                />
                                                <FormHelperText>{error?.message}</FormHelperText>
                                            </FormControl>
                                        )
                                    }}
                                />
                            </Paper>
                        </Box>
                        <Box sx={{ width: "50px", marginTop: "20px" }}>
                            <IconButton
                                onClick={() => {
                                    if (permissionsFieldsManager?.fields?.length > 1) {
                                        permissionsFieldsManager.remove(index);
                                    } else {
                                        setValue(`permGroups[${parentIndex}].permissions[${index}].module`, null);
                                        setValue(`permGroups[${parentIndex}].permissions[${index}].role`, null);
                                    }
                                }}
                            >
                                <RemoveCircleIcon />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
            {permissionsFieldsManager?.fields?.length < LICENSE_MODULES_LIST_WITHOUT_CADM?.length &&
                <IconButton
                    onClick={() => {
                        permissionsFieldsManager?.append(new Access({ module: null, role: null }));
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
            }
        </Stack>
    )
}



export { UserPermissionRenderer }