import { createContext, useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useToast } from "./ToastProvider";
import CONFIG from "../config/default";


const HttpContext = createContext();

const HttpProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { presentToast } = useToast();
    const TIMEOUT = 600000;
    const MAX_ATTEMPTS = 10;
    const VALID_HTTP_STATUSES = [200, 400, 401, 402];


    // const httpRequest = async ({ showLoading = true, getCompleteResponse = false, attempt = 1, ...req } = { req }) => {
    //     showLoading && setIsLoading(true);
    //     return axios({
    //         ...req,
    //         validateStatus: function (status) {
    //             return getCompleteResponse || VALID_HTTP_STATUSES.includes(status);
    //         },
    //         timeout: TIMEOUT,
    //         transitional: {
    //             clarifyTimeoutError: true
    //         }
    //     }).then(response => {
    //         if (response.status === 401) setIsLoggedIn(false);
    //         if (response.status === 400 && response?.data?.message) {
    //             presentToast({
    //                 severity: "error",
    //                 message: response.data.message
    //             })
    //         }
    //         return getCompleteResponse ? response : { data: response.data, status: response.status };
    //     }).catch(err => {
    //         console.log(req.url + " " + err);
    //         if (err.code === "ETIMEDOUT" && attempt < MAX_ATTEMPTS) {
    //             let attemptNumber = attempt + 1;
    //             return httpRequest({ ...req, showLoading: showLoading, getCompleteResponse: getCompleteResponse, attempt: attemptNumber });
    //         } else if (err.code === "ETIMEDOUT" && attempt >= MAX_ATTEMPTS) {
    //             presentToast({
    //                 severity: "error",
    //                 message: "Impossible de communiquer avec le serveur, veuillez relancer l'application"
    //             })
    //             return { data: undefined, status: "timeout" };
    //         }
    //         else {
    //             presentToast({
    //                 severity: "error",
    //                 message: "Erreur"
    //             })
    //             return { data: undefined, status: "erreur" };
    //         }
    //     }).finally(() => {
    //         showLoading && setIsLoading(false);
    //     })
    // }

    const httpRequest = async ({ showLoading = true, getCompleteResponse = false, attempt = 1, ...req } = { req }) => {
        showLoading && setIsLoading(true);
        return axios({
            ...req,
            validateStatus: function (status) {
                return getCompleteResponse || VALID_HTTP_STATUSES.includes(status);
            },
            timeout: TIMEOUT,
            transitional: {
                clarifyTimeoutError: true
            }
        }).then(response => {
            if (response.status === 401) setIsLoggedIn(false);
            if (response.data?.toastMessage) {
                presentToast({
                    severity: "error",
                    message: response.data?.toastMessage,
                    duration: 5000
                });
            }
            return getCompleteResponse ? response : { data: response.data, status: response.status };
        }).catch(err => {
            console.log(req.url + " " + err);
            if (err.code === "ETIMEDOUT" && attempt < MAX_ATTEMPTS) {
                let attemptNumber = attempt + 1;
                return httpRequest({ ...req, showLoading: showLoading, getCompleteResponse: getCompleteResponse, attempt: attemptNumber });
            } else if (err.code === "ETIMEDOUT" && attempt >= MAX_ATTEMPTS) {
                presentToast({
                    severity: "error",
                    message: "Impossible de communiquer avec le serveur, veuillez relancer l'application"
                })
                return { data: undefined, status: "timeout" };
            }
            else {
                presentToast({
                    severity: "error",
                    message: err?.response?.data?.toastMessage ? err?.response?.data?.toastMessage : "Erreur",
                    duration: err?.response?.data?.toastMessage ? 50000 : 5000
                });
                return { data: undefined, status: "erreur" };
            }
        }).finally(() => {
            showLoading && setIsLoading(false);
        })
    }

    return (
        <HttpContext.Provider
            value={{
                // fetchData,
                // sendData,
                // fetchDataPromise,
                setIsLoggedIn,
                isLoggedIn,
                httpRequest
            }}>
            <>
                {isLoading &&
                    <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
                        <CircularProgress size={48} color="primary" />
                    </Backdrop>
                }
                {children}
            </>
        </HttpContext.Provider>
    );
}

const useHttp = () => useContext(HttpContext);

export { HttpProvider, useHttp };