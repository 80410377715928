import { createContext, useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { RadioDialog } from '../components/RadioDialog';

const DialogContext = createContext();


const DialogProvider = ({ children }) => {
    const emptyAlertDialogState = { isOpen: false, title: undefined, message: "", buttons: [] };
    const [alertDialog, setAlertDialog] = useState(emptyAlertDialogState);
    const emptyRadioDialogState = { isOpen: false, title: undefined, radioValue: null, radioList: {}, buttons: [] };
    //radioValue must not be initialized to undefined for mui to determine radio to be a controlled component.
    //if undefined, mui decides it's an uncontrolled component and throws errors since it is actually controlled.
    const [radioDialog, setRadioDialog] = useState(emptyRadioDialogState);
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const presentAlertDialog = (props) => {
        setAlertDialog({ ...props, isOpen: true });
    }

    const dismissAlertDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            setAlertDialog(emptyAlertDialogState);
        }
    }

    const presentRadioDialog = (props) => {
        setRadioDialog({ ...props, isOpen: true });
    }

    const dismissRadioDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            setRadioDialog(emptyRadioDialogState);
        }
    }

    return (
        <DialogContext.Provider
            value={{
                presentAlertDialog,
                presentRadioDialog
            }}>
            <>
                {children}
                <div>
                    <Dialog
                        // fullScreen={fullScreen}
                        open={alertDialog.isOpen}
                        onClose={dismissAlertDialog}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {alertDialog?.title}
                        </DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText> */}
                            {/* message can contain jsx */}
                            {alertDialog.message}
                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            {alertDialog?.buttons?.length > 0 ?
                                alertDialog?.buttons?.map((button, index) => {
                                    return (
                                        <Button
                                            key={button?.text + index}
                                            onClick={async () => {
                                                if (!button?.handler) dismissAlertDialog()
                                                else {
                                                    let handlerReturn = await button.handler();
                                                    if (handlerReturn === undefined || handlerReturn === true) dismissAlertDialog();
                                                }
                                            }}
                                        >
                                            {button?.text}
                                        </Button>
                                    )
                                })
                                :
                                <Button
                                    autoFocus
                                    onClick={dismissAlertDialog}
                                >
                                    OK
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <RadioDialog
                        isOpen={radioDialog.isOpen}
                        title={radioDialog.title}
                        setRadioValue={(val) => setRadioDialog(rdg => ({ ...rdg, radioValue: val }))}
                        radioValue={radioDialog.radioValue}
                        radioList={radioDialog.radioList}
                        buttons={radioDialog.buttons}
                        dismissRadioDialog={dismissRadioDialog}
                    />
                </div>
            </>
        </DialogContext.Provider>
    );
}

const useDialog = () => useContext(DialogContext);

export { DialogProvider, useDialog };