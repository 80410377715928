//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import CONFIG from "../../config/default";
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormGroup, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack, Tooltip } from "@mui/material";
import License from "../../models/License";
import { getServerUrl } from "../../config";

export const LicensesDialog = ({ isOpen, onClose, title, license }) => {
    const theme = useTheme();
    const { establishments, lists } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();
    const { control, handleSubmit, reset, watch, trigger, formState: { submitCount } } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    useEffect(() => {
        //reset formValues
        reset();
    }, [isOpen])


    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {

        let response = undefined;
        //if license is new
        if (license?.clientAction === "new") {
            let { establishment, ...formValues } = { ...data, establishment_id: data.establishment?._id };
            //send formValues to service
            response = await httpRequest({
                url: `${getServerUrl()}/admin/customer/${customer_hid}/licenses/create`,
                data: { ...formValues },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        } else if (license?.clientAction === "modify") {
            let updatedLicense = new License({ ...license, activation_date: data?.activation_date, expiration_date: data?.expiration_date, status: data?.status });
            response = await httpRequest({
                url: `${getServerUrl()}/admin/customer/${customer_hid}/licenses/update`,
                data: { license: updatedLicense },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        }
        //handle response
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            });
            onClose();
        }
    })

    //set watches for form update
    const activationDateWatch = watch('activation_date');
    const expirationDateWatch = watch('expiration_date');
    const moduleWatch = watch('module');
    const establishmentWatch = watch('establishment');

    //manually trigger validation of watch dependant fields
    useEffect(() => {
        trigger('expiration_date');
    }, [activationDateWatch])

    useEffect(() => {
        trigger('activation_date');
    }, [expirationDateWatch])

    useEffect(() => {
        if (submitCount === 0) return;
        trigger('establishment');
    }, [moduleWatch])

    useEffect(() => {
        if (submitCount === 0) return;
        trigger('module');
    }, [establishmentWatch])

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Caractéristiques</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name={"module"}
                                        rules={{
                                            required: "Module requis",
                                            validate: {
                                                isModuleUnlocked: value => {
                                                    return !value || !establishmentWatch?.unlocked_modules || establishmentWatch.unlocked_modules?.includes(value) || "Ce module n'est pas débloqué pour cet établissement"
                                                }
                                            }
                                        }}
                                        defaultValue={lists?.LICENSE_MODULES_LIST?.find(e => e.value === license?.module)?.value || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={lists?.LICENSE_MODULES_LIST}
                                                        value={lists?.LICENSE_MODULES_LIST?.find(e => e.value === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption?.value || null)
                                                        }}
                                                        disabled={license?.clientAction === "modify"}
                                                        onBlur={onBlur}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label={
                                                                    <span>
                                                                        Module <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
                                                                    </span>
                                                                }
                                                                variant="standard"
                                                                error={error?.message ? true : false}
                                                                inputRef={ref}
                                                            />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"establishment"}
                                        rules={{
                                            required: {
                                                value: moduleWatch && moduleWatch !== "CADM",
                                                message: "Établissement requis"
                                            },
                                        }}
                                        defaultValue={license?.establishment || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={establishments}
                                                        value={value}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?._id === value._id
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.name;
                                                        }}
                                                        onBlur={onBlur}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption)
                                                        }}
                                                        disabled={license?.clientAction === "modify"}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label={
                                                                    <span>
                                                                        Établissement {moduleWatch && moduleWatch !== "CADM" ? <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} /> : ""}
                                                                    </span>
                                                                }
                                                                variant="standard"
                                                                error={error?.message ? true : false}
                                                                inputRef={ref}
                                                            />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"type"}
                                        rules={{ required: "Type requis" }}
                                        defaultValue={lists?.LICENSE_TYPES_LIST?.find(e => e.value === license?.type)?.value || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={lists?.LICENSE_TYPES_LIST}
                                                        value={lists?.LICENSE_TYPES_LIST?.find(e => e.value === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onBlur={onBlur}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption?.value || null)
                                                        }}
                                                        disabled={license?.clientAction === "modify"}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label={
                                                                    <span>
                                                                        Type <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
                                                                    </span>
                                                                }
                                                                variant="standard"
                                                                error={error?.message ? true : false}
                                                                inputRef={ref}
                                                            />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"access"}
                                        rules={{ required: "Accès requis" }}
                                        defaultValue={lists?.LICENSE_ACCESSES_LIST?.find(e => e.value === license?.access)?.value || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={lists?.LICENSE_ACCESSES_LIST}
                                                        value={lists?.LICENSE_ACCESSES_LIST?.find(e => e.value === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onBlur={onBlur}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption?.value || null)
                                                        }}
                                                        disabled={license?.clientAction === "modify"}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label={
                                                                    <span>
                                                                        Accès <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
                                                                    </span>
                                                                }
                                                                variant="standard"
                                                                error={error?.message ? true : false}
                                                                inputRef={ref}
                                                            />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"activation_date"}
                                        rules={{
                                            validate: {
                                                maxDate: value => {
                                                    let tmp = (!value || !expirationDateWatch || new Date(value) <= new Date(expirationDateWatch)) || "La date d'activation doit être avant la date d'expiration";
                                                    return tmp;
                                                }
                                            }
                                        }}
                                        defaultValue={license?.activation_date ? new Date(license?.activation_date) || null : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={frLocale}
                                                    >
                                                        <DatePicker
                                                            ref={ref}
                                                            label="Date d'activation"
                                                            maxDate={expirationDateWatch ? new Date(expirationDateWatch) : null}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={(event, selectedOption) => {
                                                                onChange(event);
                                                            }}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"expiration_date"}
                                        rules={{
                                            validate: {
                                                minDate: (value) => {
                                                    if (activationDateWatch) {
                                                        return (!value || new Date(value) >= new Date(activationDateWatch)) || "La date d'expiration doit être après la date d'activation"
                                                    }
                                                }
                                            }
                                        }}
                                        defaultValue={license?.expiration_date ? new Date(license?.expiration_date) || null : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, onBlur, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={frLocale}
                                                    >
                                                        <DatePicker
                                                            ref={ref}
                                                            label="Date d'expiration"
                                                            minDate={activationDateWatch ? new Date(activationDateWatch) : new Date()}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={(event, selectedOption) => {
                                                                onChange(event);
                                                            }}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    {license?.clientAction === "modify" &&
                                        <Controller
                                            name={"status.value"}
                                            rules={{ required: "Statut requis" }}
                                            defaultValue={lists?.LICENSE_STATUSES_LIST?.find(e => e.value === license?.status?.value)?.value || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, onBlur, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                        <Autocomplete
                                                            options={lists?.LICENSE_STATUSES_LIST}
                                                            value={lists?.LICENSE_STATUSES_LIST?.find(e => e.value === value) || null}
                                                            isOptionEqualToValue={(option, value) => {
                                                                return option?.value === value.value
                                                            }}
                                                            getOptionLabel={option => {
                                                                return option.label;
                                                            }}
                                                            onBlur={onBlur}
                                                            onChange={(event, selectedOption) => {
                                                                onChange(selectedOption?.value || null)
                                                            }}
                                                            renderInput={(params) => {
                                                                return <TextField
                                                                    {...params}
                                                                    label={
                                                                        <span>
                                                                            Statut <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
                                                                        </span>
                                                                    }
                                                                    variant="standard"
                                                                    error={error?.message ? true : false}
                                                                    inputRef={ref}
                                                                />
                                                            }}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                    {license?.clientAction === "modify" &&
                                        <Controller
                                            name="user"
                                            defaultValue={(license?.firstname && license?.lastname) ? license?.firstname + " " + license?.lastname : "libre"}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, onBlur, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                        <TextField
                                                            label={
                                                                <span>
                                                                    Affectation
                                                                </span>
                                                            }
                                                            variant="standard"
                                                            value={value}
                                                            onBlur={onBlur}
                                                            disabled={true}
                                                            inputRef={ref}
                                                            error={error?.message ? true : false}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                    {license?.clientAction === "new" &&
                                        <Controller
                                            name="nb_licenses"
                                            rules={{
                                                required: "Nombre de licences requis",
                                                validate: {
                                                    integer: value => !value || Number.isInteger(Number(value)) || "Veuillez entrer un nombre entier"
                                                }
                                            }}
                                            defaultValue={""}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, onBlur, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                        <TextField
                                                            label={
                                                                <span>
                                                                    Nombre de licences <PriorityHighIcon style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
                                                                </span>
                                                            }
                                                            variant="standard"
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={event => onChange(event?.target?.value)}
                                                            inputRef={ref}
                                                            error={error?.message ? true : false}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}