//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import Customer from "../../models/Customer";

export const CustomersDialog = ({ isOpen, onClose, title, customer }) => {
    const theme = useTheme();
    const { lists } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();
    const { control, handleSubmit, reset, setFocus } = useForm({
        mode: "onChange"
    });

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        //reset formValues
        reset();
    }, [customer])

    useEffect(() => {
        setFocus("legal_entity");
    }, [setFocus]);

    const handleClickCancel = () => {
        onClose();
    }

    const onSubmit = async (data) => {
        console.log(data);
        let formValues = { ...data };
        //copy customer then overrides customer values by formValues
        let customerTmp = new Customer({ ...customer, ...formValues });
        let response = undefined;
        if (customer?.clientAction === "new") {
            response = await httpRequest({
                url: getServerUrl() + "/admin/customers/create",
                data: { customer: customerTmp },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        } else {
            //check if customer has been altered
            let hasChanged = !compareObjectKeyValuePairs(customer, customerTmp);
            if (hasChanged) {
                response = await httpRequest({
                    url: getServerUrl() + "/admin/customers/update",
                    data: { customer: customerTmp },
                    method: "post",
                    headers: { "Content-type": "Application/json" },
                    withCredentials: true
                }, true, true);

            } else {
                presentToast({
                    severity: "warning",
                    message: `${title}: aucun champ modifié`
                })
            }
        }
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            onClose();
        }
    }

    const getFirstRef = (errors) => {
        if (!errors || typeof errors !== 'object') return null;

        if (errors.ref) {
            return errors.ref;
        }

        for (const key in errors) {
            const result = getFirstRef(errors[key]);
            if (result) {
                return result;
            }
        }

        return null;
    }

    const onError = (errors) => {
        //if there are some errors, we scroll to the first one from the top and focus it
        if (Object.keys(errors)?.length === 0) return;
        let ref = getFirstRef(errors);
        debugger;

        if (!ref) return;

        let element = document.getElementById(ref?.name);

        if (!element) return;
        // // element.scrollIntoView({ behavior: "smooth" });
        // const y = element.getBoundingClientRect().top;
        // console.log(y)

        // // window.scrollTo({ top: 500, behavior: 'smooth' });
        element.focus();
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            // onClose={ }
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Informations client</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="legal_entity"
                                        rules={{
                                            required: "Nom du client requis",
                                            minLength: {
                                                value: 3,
                                                message: '3 caractères min'
                                            }
                                        }}
                                        defaultValue={customer?.legal_entity || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom du client"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="legal_person"
                                        rules={{
                                            required: "Personne morale requise",
                                            minLength: {
                                                value: 3,
                                                message: '3 caractères min'
                                            }
                                        }}
                                        defaultValue={customer?.legal_person || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Personne morale"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="human_id"
                                        rules={{
                                            required: "ID client requis",
                                            minLength: {
                                                value: 4,
                                                message: "L'ID client doit faire 4 caractères min"
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "L'ID client doit faire 12 caractères max"
                                            }
                                        }}
                                        defaultValue={customer?.human_id || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="ID client"
                                                        variant="standard"
                                                        value={value}
                                                        disabled={customer?.clientAction !== "new"}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="tva_number"
                                        rules={{
                                            required: "Numéro de TVA requis",
                                            maxLength: {
                                                value: 13,
                                                message: 'Le numéro de TVA doit faire 13 caractères'
                                            },
                                            minLength: {
                                                value: 13,
                                                message: 'Le numéro de TVA doit faire 13 caractères'
                                            }
                                        }}
                                        defaultValue={customer?.tva_number || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Numéro de TVA"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="siret"
                                        rules={{
                                            required: "Numéro de siret requis",
                                            minLength: {
                                                value: 14,
                                                message: 'Le numéro de siret doit faire 14 caractères'
                                            },
                                            maxLength: {
                                                value: 14,
                                                message: 'Le numéro de siret doit faire 14 caractères'
                                            }
                                        }}
                                        defaultValue={customer?.siret || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Numéro SIRET"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="capacity"
                                        rules={{
                                            required: "Capacité requise",
                                            pattern: {
                                                value: /^\d+$/,
                                                message: 'Doit être un nombre'
                                            }
                                        }}
                                        defaultValue={customer?.capacity || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Capacité"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="website"
                                        rules={{
                                            pattern: {
                                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
                                                message: 'URL invalide'
                                            }
                                        }}
                                        defaultValue={customer?.website || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Site internet"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Personne de contact</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="contact.firstname"
                                        rules={{}}
                                        defaultValue={customer?.contact?.firstname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Prénom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.lastname"
                                        rules={{}}
                                        defaultValue={customer?.contact?.lastname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.job"
                                        rules={{}}
                                        defaultValue={customer?.contact?.job || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Poste"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.tel"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de téléphone invalide"
                                            }
                                        }}
                                        defaultValue={customer?.contact?.tel || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Tel"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.fax"
                                        rules={{
                                            pattern: {
                                                value: /^(?:(?:\+|00)33|0)\d{9}$/,
                                                message: "Numéro de fax invalide"
                                            }
                                        }}
                                        defaultValue={customer?.contact?.fax || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Fax"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="contact.email"
                                        rules={{
                                            pattern: {
                                                value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                                                message: "Email invalide"
                                            }
                                        }}
                                        defaultValue={customer?.contact?.email || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Email"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Adresse de facturation</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="billing_address.street_number"
                                        rules={{}}
                                        defaultValue={customer?.billing_address?.street_number || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Numéro de rue"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="billing_address.street_name"
                                        rules={{ required: 'Nom de rue requis' }}
                                        defaultValue={customer?.billing_address?.street_name || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom de rue"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="billing_address.complement"
                                        rules={{}}
                                        defaultValue={customer?.billing_address?.complement || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Complement"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="billing_address.zipcode"
                                        rules={{
                                            required: 'Code postal requis',
                                            minLength: {
                                                value: 5,
                                                message: '5 caractères requis'
                                            },
                                            maxLength: {
                                                value: 5,
                                                message: '5 caractères requis'
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: 'Code postal invalide'
                                            }
                                        }}
                                        defaultValue={customer?.billing_address?.zipcode || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Code postal"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="billing_address.city"
                                        rules={{ required: 'Ville requise' }}
                                        defaultValue={customer?.billing_address?.city || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, name, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Ville"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                        id={name}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}