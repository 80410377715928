//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm, useFieldArray, FormProvider } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
import { UserPermissionRenderer } from "./UserPermissionRenderer";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { compareObjects } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Access } from "../../models/Permission";



const UserPermissionsDialog = ({ isOpen, onClose, title, permGroups, CADMPermission, user }) => {
    const theme = useTheme();

    const { lists, establishments } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const RHF = useForm({
        mode: "all",
    });
    const { control, handleSubmit, reset, setError, formState: { errors, isSubmitSuccessful } } = RHF;

    //hook to handle dynamic fields for strengths
    const permGroupsManager = useFieldArray({
        name: "permGroups",
        control
    });

    useEffect(() => {
        if (permGroups && CADMPermission) {
            let defaultValues = {};
            defaultValues["permGroups"] = permGroups;
            defaultValues["CADMPermission"] = CADMPermission;
            reset(defaultValues);
        }
    }, [permGroups, CADMPermission])

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const userHasEmail = () => {
        return !!user?.email?.value;
    }

    const handleClickCancel = () => {
        reset();
        onClose();
    }

    const getEstablishmentsNames = (ids) => {
        let string = "";
        for (let [index, id] of ids.entries()) {
            let estTmp = establishments.find(e => e._id === id);
            if (estTmp) {
                string += estTmp.name;
            }
            if (index + 1 < ids.length) {
                string += ", ";
            }
        }
        return string;
    }

    const handleClickValidate = handleSubmit(async (data) => {
        console.log(data);
        let formValues = { ...data, CADMPermission: { ...CADMPermission, module: lists?.LICENSE_MODULE_VALUES["CADM"] } };

        //check if all establishments have unlocked modules for all modules
        let hasErrors = false;
        for (let [groupIndex, permGroup] of formValues?.permGroups.entries()) {
            for (let [permIndex, permission] of permGroup.permissions.entries()) {
                let locked_modules_establishment_ids = [];
                for (let establishment_id of permGroup.establishment_ids) {
                    if (!establishments?.find(e => e?._id === establishment_id)?.unlocked_modules?.includes(permission?.module)) {
                        locked_modules_establishment_ids.push(establishment_id);
                        hasErrors = true;
                    }
                }
                if (locked_modules_establishment_ids.length > 0) {
                    let names = getEstablishmentsNames(locked_modules_establishment_ids);
                    setError(`permGroups[${groupIndex}].permissions[${permIndex}].module`, {
                        type: "manual",
                        message: `Modules non débloqués pour le(s) établissement(s): ${names}`
                    })
                }
            }
        }

        //if any error found, abort function
        if (hasErrors) return;

        let response = await httpRequest({
            url: `${getServerUrl()}/admin/customer/${customer_hid}/user/${user._id}/permissions/update`,
            data: { permGroups: formValues.permGroups, CADMPermission: formValues.CADMPermission },
            method: "post",
            headers: { "Content-type": "Application/json" },
            withCredentials: true
        }, true, true);

        if (response.status === 200) {
            let hasFeedbackErrors = false;
            //store potential errors in feedback
            let feedback = response.data;
            //check for CADMPermissions
            if (feedback.CADMPermission?.error === "error_no_available_license") {
                hasFeedbackErrors = true;
                setError(`CADMPermission.role`, {
                    type: "manual",
                    message: "Pas de license disponible"
                })
            }
            if (feedback.CADMPermission?.error === "error_user_has_no_email") {
                hasFeedbackErrors = true;
                setError(`CADMPermission.role`, {
                    type: "manual",
                    message: "L'utilisateur doit avoir un email pour choisir ce rôle"
                })
            }

            //check for all other permissions
            for (let [groupIndex, permGroup] of feedback.permGroups.entries()) {
                for (let [permIndex, permission] of permGroup.permissions.entries()) {
                    if (permission?.error?.type === "error_no_available_license") {
                        hasFeedbackErrors = true;
                        let names = getEstablishmentsNames(permission?.error?.establishment_ids);
                        setError(`permGroups[${groupIndex}].permissions[${permIndex}].role`, {
                            type: "manual",
                            message: `Pas de licence disponible pour le(s) établissement(s): ${names}`
                        });
                    }
                    if (permission?.error?.type === "error_user_has_no_email") {
                        setError(`permGroups[${groupIndex}].permissions[${permIndex}].role`, {
                            type: "manual",
                            message: `L'utilisateur doit avoir un email pour choisir ce rôle`
                        });
                    }
                }
            }
            if (!hasFeedbackErrors) {
                presentToast({
                    severity: "success",
                    message: `${title}: succès`
                });
                let defaultValues = {};
                reset(
                    defaultValues["permGroups"] = [],
                    defaultValues["CADMPermission"] = {});
                onClose();
            }
        }

    })


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <FormProvider {...RHF}>
                            {/* render first CADM permission */}
                            <Accordion
                                sx={{
                                    marginBottom: "24px"
                                }}
                                defaultExpanded
                            >
                                <AccordionSummary
                                    sx={{ ...(!!errors?.CADMPermission?.role && { color: theme.palette.error.main }) }}
                                >
                                    <Typography>Administration client</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Controller
                                        name={`CADMPermission.role`}
                                        rules={{
                                            validate: (value) => {
                                                if (value === lists.ROLE_VALUES["RDR"]) {
                                                    return true;
                                                } else if (userHasEmail()) {
                                                    return true;
                                                } else if (!value) {
                                                    return true;
                                                }
                                                return "L'utilisateur doit avoir un email pour choisir ce rôle";
                                            }
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;

                                            //filter roleOptions
                                            let rolesOptions = [];
                                            let allowedRoles = lists?.MODULES_ROLES_VALUES?.find(e => e.module === lists?.LICENSE_MODULE_VALUES["CADM"])?.roles;
                                            rolesOptions = lists?.ROLES_LIST?.filter(e => allowedRoles?.includes(e.value));

                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={rolesOptions}
                                                        value={rolesOptions?.find(e => e.value === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value?.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption?.value || null)
                                                        }}
                                                        renderInput={(params) => {
                                                            return <TextField {...params} label="Role" variant="standard" inputRef={ref} />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            {permGroupsManager?.fields?.map((field, index) => {
                                //check if any errors in the group for dynamic accordion styling
                                let hasGroupError = Boolean(errors?.permGroups?.[index]?.establishment_ids || errors?.permGroups?.[index]?.permissions);
                                return (
                                    <Accordion
                                        defaultExpanded
                                        key={field.id}
                                        sx={{ marginBottom: "24px" }}
                                    >
                                        <AccordionSummary
                                            sx={{ ...(hasGroupError && { color: theme.palette.error.main }) }}
                                        >
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <Typography>Groupe de permissions n°{index + 1}</Typography>
                                                <Button
                                                    onClick={() => {
                                                        permGroupsManager.remove(index);
                                                    }}
                                                >
                                                    supprimer groupe
                                                </Button>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/* this part is in component to nest the useFieldArrays, enabling to add / remove fields on parents AND children */}
                                            <UserPermissionRenderer
                                                parentIndex={index}
                                                userHasEmail={userHasEmail()}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                            <Button
                                onClick={() => {
                                    permGroupsManager.append({ establishment_ids: [], permissions: [new Access({ module: null, role: null })] });
                                }}
                            >
                                ajouter groupe
                            </Button>
                        </FormProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { UserPermissionsDialog }