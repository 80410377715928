class Access {
    constructor(a) {
        this.module = a?.module;
        this.role = a?.role;
    }
}

class Permission {
    constructor(p) {
        this._id = p?._id;
        this.user_id = p?.user_id;
        this.establishment_id = p?.establishment_id;
        this.module = p?.module;
        this.role = p?.role;
        this.changeType = p?.changeType;
        this.created = {
            by: p?.created?.by,
            on: p?.created?.on
        }
        this.clientAction = p?.clientAction;
    }
}




export { Permission, Access };
