class Address {

    constructor(a) {
        this.street_number = a?.street_number;
        this.street_name = a?.street_name;
        this.complement = a?.complement;
        this.zipcode = a?.zipcode;
        this.city = a?.city;
    }
}
export default Address;