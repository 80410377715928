//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import ListItem from '@mui/material/ListItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmerReducer } from 'use-immer';
import { original } from 'immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
import { AddDialog } from "./AddDialog";
import { ChapterDialog } from "./ChapterDialog";
import { ObjectiveDialog } from "./ObjectiveDialog";
import { CriterionDialog } from "./CriterionDialog";
import { ObservationDialog } from "./ObservationDialog";
import { ReleaseDialog } from "./ReleaseDialog";
import { ArchiveDialog } from "./ArchiveDialog";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { getDateString } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, stItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Referential, Chapter, Objective, Criterion, Observation } from "../../models/Referential";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ReferentialAdmin = ({ status }) => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, lists } = useUser();

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const emptyAddDialogState = { isOpen: false };
    const [addDialogState, setAddDialogState] = useState(emptyAddDialogState);
    const emptyChapterDialogState = { isOpen: false, chapterNumber: undefined, mode: undefined }
    const [chapterDialogState, setChapterDialogState] = useState(emptyChapterDialogState);
    const emptyObjectiveDialogState = { isOpen: false, chapterNumber: undefined, objectiveNumber: undefined, mode: undefined };
    const [objectiveDialogState, setObjectiveDialogState] = useState(emptyObjectiveDialogState);
    const emptyCriterionDialogState = { isOpen: false, chapterNumber: undefined, objectiveNumber: undefined, criterionNumber: undefined, mode: undefined };
    const [criterionDialogState, setCriterionDialogState] = useState(emptyCriterionDialogState);
    const emptyObservationDialogState = { isOpen: false, chapterNumber: undefined, objectiveNumber: undefined, criterionNumber: undefined, observationNumber: undefined, mode: undefined };
    const [observationDialogState, setObservationDialogState] = useState(emptyObservationDialogState);
    const emptyReleaseDialogState = { isOpen: false };
    const [releaseDialogState, setReleaseDialogState] = useState(emptyReleaseDialogState);
    const emptyArchiveDialogState = { isOpen: false };
    const [archiveDialogState, setArchiveDialogState] = useState(emptyArchiveDialogState);
    const [selectedArchivedRef, setSelectedArchivedRef] = useState({});
    const activityFilter = useRef([]);
    const searchFilter = useRef("");
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();

    const criterionStripeColor = "rgba(18, 125, 120, 0.3)";


    const reducer = (draft, action) => {
        switch (action?.type) {
            case "init":
                return action.init();
            case "update":
                action.update(draft);
                draft.is_modified = draft.isModified();
                draft.contain_empty_parents = draft.containEmptyParents();
                break;
            case "update_and_filter":
                action.update(draft);
                draft.is_modified = draft.isModified();
                draft.contain_empty_parents = draft.containEmptyParents();
            case "filter":
                draft.filter(searchFilter, activityFilter);
                break;
            case "expand_all":
                draft.expand();
                break;
            case "expand_item":
                action.getItem(draft).expand();
                break;
            case "show_archived":
                draft.showArchivedElements();
                break;
        }
    }

    //selected referential for display and interactions
    const [referential, dispatchReferential] = useImmerReducer(reducer, new Referential());

    //archived referentials 
    const [archivedReferentialsList, setArchivedReferentialsList] = useState(undefined);

    useEffect(() => {
        if (!status) return;
        const fetchBenchmark = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: getServerUrl() + "/admin/referential/fetch/" + status,
                method: 'get',
                withCredentials: true
            });
            if (response.status === 200) {
                let ref;
                if (status === "archived") {
                    setSelectedArchivedRef(response.data[response.data.length - 1]);
                    setArchivedReferentialsList(response.data);
                    ref = new Referential(response.data[response.data.length - 1]);
                } else {
                    ref = new Referential(response.data);
                }
                //initialization of the state must be done with a returned function. Otherwise, the rerender is not triggered.
                //So we manually filter the ref before initialization
                ref.filter(searchFilter, activityFilter);
                dispatchReferential({
                    type: "init",
                    init: () => ref
                });
            }
            setIsLoading(false);
        }
        fetchBenchmark();
    }, [status])

    const handleAdd = (value) => {
        if (!new RegExp(/^[0-9]+(.[0-9]+)?(.[0-9]+)?(.[0-9]+)?$/).test(value)) {
            presentToast({
                message: "Saisie invalide",
                severity: "error"
            });
            return;
        }
        let error = false;
        let message = "";
        let numbers = value.split(".");
        if (!numbers || numbers.length === 0) return;
        //check if number is not too big - we need to always respect maxNumber = array.length + 1 
        if (numbers.length >= 1 && referential?.chapters?.length < numbers[0] - 1) {
            error = true;
            message = `il n'y a que ${referential.chapters.length} chapitres`;
        }
        if (numbers.length >= 2 && referential?.chapters[numbers[0] - 1]?.objectives?.length < numbers[1] - 1) {
            error = true;
            message = `il n'y a que ${referential.chapters[numbers[0] - 1].objectives.length} objectifs`;
        }
        if (numbers.length >= 3 && referential?.chapters[numbers[0] - 1]?.objectives[numbers[1] - 1]?.criteria?.length < numbers[2] - 1) {
            error = true;
            message = `il n'y a que ${referential.chapters[numbers[0] - 1].objectives[numbers[1] - 1].criteria.length} critères`;
        }
        if (numbers.length === 4 && referential?.chapters[numbers[0] - 1]?.objectives[numbers[1] - 1]?.criteria[numbers[2] - 1]?.observations.length < numbers[3] - 1) {
            error = true;
            message = `il n'y a que ${referential.chapters[numbers[0] - 1].objectives[numbers[1] - 1].criteria[numbers[2] - 1].observations.length} constats`;
        }
        //check if entity to add has an existing parent
        if (numbers.length >= 2 && referential?.chapters[numbers[0] - 1] === undefined) {
            error = true;
            message = `il n'existe pas de chapitre ${numbers[0]}`;
        }
        if (numbers.length >= 3 && !referential?.chapters[numbers[0] - 1]?.objectives[numbers[1] - 1]) {
            error = true;
            message = `il n'existe pas d'objectif ${numbers[1]}`;
        }
        if (numbers.length === 4 && !referential?.chapters[numbers[0] - 1]?.objectives[numbers[1] - 1]?.criteria[numbers[2] - 1]) {
            error = true;
            message = `il n'existe pas de critère ${numbers[2]}`;
        }
        if (error) {
            presentToast({
                message: message,
                severity: "error"
            });
            return;
        }
        if (numbers.length === 1) setChapterDialogState({ isOpen: true, chapterNumber: numbers[0], mode: "insert" });
        else if (numbers.length === 2) setObjectiveDialogState({ isOpen: true, chapterNumber: numbers[0], objectiveNumber: numbers[1], mode: "insert" });
        else if (numbers.length === 3) setCriterionDialogState({ isOpen: true, chapterNumber: numbers[0], objectiveNumber: numbers[1], criterionNumber: numbers[2], mode: "insert" });
        else if (numbers.length === 4) setObservationDialogState({ isOpen: true, chapterNumber: numbers[0], objectiveNumber: numbers[1], criterionNumber: numbers[2], observationNumber: numbers[3], mode: "insert" });
        setAddDialogState(emptyAddDialogState);
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", width: "100%", gap: "20px", padding: "8px" }}>
                <FormControl sx={{ width: "300px" }} variant="standard">
                    <AutocompleteWithAllOption
                        options={lists?.ACTIVITY_LIST}
                        getOptionLabel={option => `${option?.short}`}
                        value={activityFilter.current?.map(v => lists?.ACTIVITY_LIST.find(e => e.value === v))}
                        limitTags={2}
                        onChange={(arr) => {
                            activityFilter.current = arr?.map(e => e.value) || [];
                            dispatchReferential({
                                type: "filter"
                            })
                        }}
                        label="Activité(s) concernée(s)"
                    />
                </FormControl>
                <TextField
                    id="search"
                    type="search"
                    label="Filtre constats"
                    variant="standard"
                    onChange={(event) => {
                        searchFilter.current = event.target.value;
                        dispatchReferential({
                            type: "filter"
                        })
                    }}
                    sx={{ width: "400px", display: "flex", flexShrink: 1 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                {status === "archived" &&
                    <FormControl sx={{ width: "300px" }} variant="standard">
                        <Autocomplete
                            options={archivedReferentialsList || []}
                            getOptionLabel={option => getDateString(option?.HAS_date)}
                            isOptionEqualToValue={(option, value) => {
                                return option?.HAS_date === value?.HAS_date
                            }}
                            value={selectedArchivedRef}
                            renderInput={(params) => <TextField {...params} label="Référentiel" variant="standard" />}
                            onChange={(event, value) => {
                                let ref = new Referential(value);
                                dispatchReferential({
                                    type: "init",
                                    init: () => ref
                                });
                                setSelectedArchivedRef(value);
                            }}
                        />
                    </FormControl>
                }
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    {status !== "archived" &&
                        <IconButton
                            variant='outlined'
                            disabled={(status === "released" && referential.isEmpty())}
                            onClick={() => {
                                setAddDialogState({ isOpen: true });
                            }}><AddCircleIcon />
                        </IconButton>
                    }
                    <IconButton
                        disabled={referential?.chapters?.length === 0}
                        onClick={() => dispatchReferential({ type: "expand_all" })}>
                        {referential?.expandAll ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                    </IconButton>
                    {status !== "draft" &&
                        <IconButton
                            disabled={referential?.chapters?.length === 0}
                            onClick={() => dispatchReferential({ type: "show_archived" })}>
                            {referential?.showArchived ? <ArchiveIcon /> : <UnarchiveIcon />}
                        </IconButton>
                    }
                </Box>
            </Toolbar>
            <Box sx={{ overflowY: "auto", height: `calc(100vh - 192px)` }} >
                {!isLoading &&
                    <>
                        {referential?.chapters?.length === 0 ?
                            <Box sx={{ position: "fixed", top: "50vh", left: "calc(50vw - 200px)" }}>
                                <div style={{ width: "400px", textAlign: "center", fontWeight: "bold" }}>Aucun référentiel trouvé</div>
                            </Box>
                            :
                            <>
                                <List
                                    sx={{ width: '100%', padding: "16px", bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <ListItemText />
                                    }
                                >
                                    {referential?.chapters?.map((chapter, chapterIndex) => {
                                        //*******************************************************************chapitres**********************************************************************
                                        if (!chapter.hidden && (referential?.showArchived || (!referential?.showArchived && !chapter.is_archived))) return (
                                            <div key={chapter.name + chapterIndex}>
                                                <ListItemButton
                                                    sx={{
                                                        ":hover": {
                                                            backgroundColor: "#DDEEF8"
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        dispatchReferential({
                                                            type: "expand_item",
                                                            getItem: draft => draft.chapters[chapterIndex]
                                                        });
                                                    }}>
                                                    <ListItemText primaryTypographyProps={{ fontSize: '1.5rem' }} primary={`${chapterIndex + 1}) ${chapter.name}`} />
                                                    {(status !== "archived") && (
                                                        <>
                                                            <IconButton onClick={e => {
                                                                e.stopPropagation();
                                                                setChapterDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, mode: "modify" });
                                                            }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton onClick={e => {
                                                                e.stopPropagation();
                                                                setChapterDialogState({ isOpen: true, chapterNumber: chapterIndex + 2, mode: "insert" });
                                                            }}>
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                            {((status === "released" && chapter.clientAction === "new") || status === "draft") && (
                                                                <IconButton sx={{ marginRight: "16px" }} onClick={e => {
                                                                    e.stopPropagation();
                                                                    presentAlertDialog({
                                                                        message: `Supprimer le chapitre ${chapterIndex + 1} ainsi que tous ses enfants ?`,
                                                                        buttons: [
                                                                            {
                                                                                text: "Annuler"
                                                                            },
                                                                            {
                                                                                text: "Confirmer",
                                                                                handler: () => {
                                                                                    dispatchReferential({
                                                                                        type: "update",
                                                                                        update: draft => {
                                                                                            draft.deleteChapter(chapterIndex);
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                        ]
                                                                    })
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )}
                                                        </>
                                                    )}
                                                    {((status === "released" && chapter.clientAction !== "new") || (status === "archived")) && (
                                                        <IconButton
                                                            disabled={status === "archived"}
                                                            sx={{ marginRight: "16px" }}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                presentAlertDialog({
                                                                    message: chapter.is_archived ? `Restaurer le chapitre ${chapterIndex + 1} ?` : `Archiver le chapitre ${chapterIndex + 1} ainsi que tous ses enfants ?`,
                                                                    buttons: [
                                                                        {
                                                                            text: "Annuler"
                                                                        },
                                                                        {
                                                                            text: "Confirmer",
                                                                            handler: () => {
                                                                                dispatchReferential({
                                                                                    type: "update",
                                                                                    update: draft => {
                                                                                        let chapterTmp = draft.chapters[chapterIndex];
                                                                                        chapterTmp.is_archived ? chapterTmp.unarchive() : chapterTmp.archive();
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                    ]
                                                                })
                                                            }}>
                                                            {chapter.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                                        </IconButton>
                                                    )}
                                                    {chapter.isOpen ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={chapter.isOpen} timeout="auto" unmountOnExit>
                                                    {/* **********************************************************objectives****************************************************************** */}
                                                    <List component="div" disablePadding>
                                                        {chapter?.objectives?.map((objective, objectiveIndex) => {
                                                            if (!objective.hidden && (referential?.showArchived || (!referential?.showArchived && !objective.is_archived))) return (
                                                                <div key={objective.name + objectiveIndex}>
                                                                    <ListItemButton sx={{
                                                                        pl: 4,
                                                                        ":hover": {
                                                                            backgroundColor: "#DDEEF8"
                                                                        }
                                                                    }}
                                                                        onClick={() => {
                                                                            dispatchReferential({
                                                                                type: "expand_item",
                                                                                getItem: draft => draft.chapters[chapterIndex].objectives[objectiveIndex]
                                                                            });
                                                                        }}>
                                                                        <ListItemText primaryTypographyProps={{ fontSize: '1.2rem' }} primary={`${chapterIndex + 1}.${objectiveIndex + 1}) ${objective.name}`} />
                                                                        {(status !== "archived") && (
                                                                            <>
                                                                                <IconButton onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    setObjectiveDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 1, mode: "modify" });
                                                                                }}>
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                                <IconButton onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    setObjectiveDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 2, mode: "insert" });
                                                                                }}>
                                                                                    <AddCircleIcon />
                                                                                </IconButton>
                                                                                {((status === "released" && objective.clientAction === "new") || status === "draft") && (
                                                                                    <IconButton
                                                                                        sx={{ marginRight: "16px" }}
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            presentAlertDialog({
                                                                                                message: `Supprimer l'objectif ${objectiveIndex + 1} ainsi que tous ses enfants ?`,
                                                                                                buttons: [
                                                                                                    {
                                                                                                        text: "Annuler"
                                                                                                    },
                                                                                                    {
                                                                                                        text: "Confirmer",
                                                                                                        handler: () => {
                                                                                                            dispatchReferential({
                                                                                                                type: "update",
                                                                                                                update: draft => {
                                                                                                                    draft.chapters[chapterIndex].deleteObjective(objectiveIndex);
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        }}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {((status === "released" && objective.clientAction !== "new") || (status === "archived")) && (
                                                                            <IconButton
                                                                                sx={{ marginRight: "16px" }}
                                                                                disabled={status === "archived"}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    presentAlertDialog({
                                                                                        message: objective.is_archived ? `Restaurer l'objectif ${objectiveIndex + 1} et tous ses parents ?` : `Archiver l'objectif ${objectiveIndex + 1} et tous ses enfants ?`,
                                                                                        buttons: [
                                                                                            {
                                                                                                text: "Annuler"
                                                                                            },
                                                                                            {
                                                                                                text: "Confirmer",
                                                                                                handler: () => {
                                                                                                    dispatchReferential({
                                                                                                        type: "update",
                                                                                                        update: draft => {
                                                                                                            let chapterTmp = draft.chapters[chapterIndex];
                                                                                                            let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                            if (objectiveTmp.is_archived) {
                                                                                                                //if unarchive child, also unarchive its parents
                                                                                                                objectiveTmp.unarchive();
                                                                                                                chapterTmp.unarchive();
                                                                                                            } else {
                                                                                                                objectiveTmp.archive();
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        ]
                                                                                    })
                                                                                }}>
                                                                                {objective.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                                                            </IconButton>
                                                                        )}
                                                                        {objective.isOpen ? <ExpandLess /> : <ExpandMore />}
                                                                    </ListItemButton>
                                                                    <Collapse in={objective.isOpen} timeout="auto" unmountOnExit>
                                                                        {/* ******************************************criteria***************************************************************** */}
                                                                        <List component="div" disablePadding>
                                                                            {objective?.criteria?.map((criterion, criterionIndex) => {
                                                                                if (!criterion.hidden && (referential?.showArchived || (!referential?.showArchived && !criterion.is_archived))) return (
                                                                                    <div key={criterion.name + criterionIndex}>
                                                                                        <ListItemButton sx={{
                                                                                            pl: 8,
                                                                                            ":hover": {
                                                                                                backgroundColor: "#DDEEF8"
                                                                                            },
                                                                                            backgroundColor: criterionStripeColor
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                dispatchReferential({
                                                                                                    type: "expand_item",
                                                                                                    getItem: draft => draft.chapters[chapterIndex].objectives[objectiveIndex].criteria[criterionIndex]
                                                                                                });
                                                                                            }}>

                                                                                            <ListItemText primaryTypographyProps={{ fontSize: '1rem' }} primary={`${chapterIndex + 1}.${objectiveIndex + 1}.${criterionIndex + 1}) ${criterion.name}`} />
                                                                                            {criterion.important &&
                                                                                                <Chip variant="outlined" color="error" label='Impératif' icon={<ReportIcon />} />
                                                                                            }
                                                                                            {(status !== "archived") && (
                                                                                                <>
                                                                                                    <IconButton
                                                                                                        onClick={e => {
                                                                                                            e.stopPropagation();
                                                                                                            presentAlertDialog({
                                                                                                                message: `Déplacer ce critère et tous ses enfants vers le haut ?`,
                                                                                                                buttons: [
                                                                                                                    {
                                                                                                                        text: "Annuler"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        text: "Confirmer",
                                                                                                                        handler: () => {
                                                                                                                            dispatchReferential({
                                                                                                                                type: "update",
                                                                                                                                update: draft => {
                                                                                                                                    let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                    let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                    objectiveTmp.shiftCriterionUp(criterionIndex);
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }
                                                                                                                ]
                                                                                                            })
                                                                                                        }}
                                                                                                        disabled={criterionIndex === 0}
                                                                                                    >
                                                                                                        <ArrowUpwardIcon />
                                                                                                    </IconButton>
                                                                                                    <IconButton
                                                                                                        onClick={e => {
                                                                                                            e.stopPropagation();
                                                                                                            presentAlertDialog({
                                                                                                                message: `Déplacer ce critère et tous ses enfants vers le bas ?`,
                                                                                                                buttons: [
                                                                                                                    {
                                                                                                                        text: "Annuler"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        text: "Confirmer",
                                                                                                                        handler: () => {
                                                                                                                            dispatchReferential({
                                                                                                                                type: "update",
                                                                                                                                update: draft => {
                                                                                                                                    let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                    let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                    objectiveTmp.shiftCriterionDown(criterionIndex);
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }
                                                                                                                ]
                                                                                                            })
                                                                                                        }}
                                                                                                        disabled={criterionIndex === objective?.criteria?.length - 1}
                                                                                                    >
                                                                                                        <ArrowDownwardIcon />
                                                                                                    </IconButton>
                                                                                                    <IconButton onClick={e => {
                                                                                                        e.stopPropagation();
                                                                                                        setCriterionDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 1, criterionNumber: criterionIndex + 1, mode: "modify" });
                                                                                                    }}>
                                                                                                        <EditIcon />
                                                                                                    </IconButton>
                                                                                                    <IconButton onClick={e => {
                                                                                                        e.stopPropagation();
                                                                                                        setCriterionDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 1, criterionNumber: criterionIndex + 2, mode: "insert" });
                                                                                                    }}>
                                                                                                        <AddCircleIcon />
                                                                                                    </IconButton>
                                                                                                    {((status === "released" && criterion.clientAction === "new") || status === "draft") && (
                                                                                                        <IconButton
                                                                                                            sx={{ marginRight: "16px" }}
                                                                                                            onClick={e => {
                                                                                                                e.stopPropagation();
                                                                                                                presentAlertDialog({
                                                                                                                    message: `Supprimer le critère ${criterionIndex + 1} ainsi que tous ses enfants ?`,
                                                                                                                    buttons: [
                                                                                                                        {
                                                                                                                            text: "Annuler"
                                                                                                                        },
                                                                                                                        {
                                                                                                                            text: "Confirmer",
                                                                                                                            handler: () => {
                                                                                                                                dispatchReferential({
                                                                                                                                    type: "update",
                                                                                                                                    update: draft => {
                                                                                                                                        draft.chapters[chapterIndex].objectives[objectiveIndex].deleteCriterion(criterionIndex);
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            }
                                                                                                                        }
                                                                                                                    ]
                                                                                                                })
                                                                                                            }}>
                                                                                                            <DeleteIcon />
                                                                                                        </IconButton>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                            {((status === "released" && criterion.clientAction !== "new") || status === "archived") && (
                                                                                                <IconButton
                                                                                                    sx={{ marginRight: "16px" }}
                                                                                                    disabled={status === "archived"}
                                                                                                    onClick={e => {
                                                                                                        e.stopPropagation();
                                                                                                        presentAlertDialog({
                                                                                                            message: criterion.is_archived ? `Restaurer le critère ${criterionIndex + 1} ainsi que ses parents ?` : `Archiver le critère ${criterionIndex + 1} ainsi que ses enfants ?`,
                                                                                                            buttons: [
                                                                                                                {
                                                                                                                    text: "Annuler"
                                                                                                                },
                                                                                                                {
                                                                                                                    text: "Confirmer",
                                                                                                                    handler: () => {
                                                                                                                        dispatchReferential({
                                                                                                                            type: "update",
                                                                                                                            update: draft => {
                                                                                                                                let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                let criterionTmp = objectiveTmp.criteria[criterionIndex];
                                                                                                                                if (criterionTmp.is_archived) {
                                                                                                                                    //if unarchive child, also unarchive its parents
                                                                                                                                    criterionTmp.unarchive();
                                                                                                                                    objectiveTmp.unarchive();
                                                                                                                                    chapterTmp.unarchive();
                                                                                                                                } else {
                                                                                                                                    criterionTmp.archive();
                                                                                                                                }
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                }
                                                                                                            ]
                                                                                                        })
                                                                                                    }}>
                                                                                                    {criterion.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                                                                                </IconButton>
                                                                                            )}
                                                                                            {criterion.isOpen ? <ExpandLess /> : <ExpandMore />}
                                                                                        </ListItemButton>
                                                                                        <Collapse in={criterion.isOpen} timeout="auto" unmountOnExit>
                                                                                            {/* ********************observations***************************************************************** */}
                                                                                            <List component="div" disablePadding>
                                                                                                {criterion?.observations?.map((observation, observationIndex) => {
                                                                                                    if (!observation.hidden && (referential?.showArchived || (!referential?.showArchived && !observation.is_archived))) return (
                                                                                                        <div key={observation.question + observationIndex}>
                                                                                                            <ListItem sx={{
                                                                                                                pl: 12,
                                                                                                                ":hover": {
                                                                                                                    backgroundColor: "#DDEEF8"
                                                                                                                }
                                                                                                            }}>
                                                                                                                <ListItemText primaryTypographyProps={{ fontSize: '0.9rem' }} primary={`${chapterIndex + 1}.${objectiveIndex + 1}.${criterionIndex + 1}.${observationIndex + 1}) ${observation.question}`} />
                                                                                                                {(status !== "archived") && (
                                                                                                                    <>
                                                                                                                        <IconButton
                                                                                                                            onClick={e => {
                                                                                                                                e.stopPropagation();
                                                                                                                                presentAlertDialog({
                                                                                                                                    message: `Déplacer ce constat vers le haut ?`,
                                                                                                                                    buttons: [
                                                                                                                                        {
                                                                                                                                            text: "Annuler"
                                                                                                                                        },
                                                                                                                                        {
                                                                                                                                            text: "Confirmer",
                                                                                                                                            handler: () => {
                                                                                                                                                dispatchReferential({
                                                                                                                                                    type: "update",
                                                                                                                                                    update: draft => {
                                                                                                                                                        let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                                        let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                                        let criterionTmp = objectiveTmp.criteria[criterionIndex];
                                                                                                                                                        criterionTmp.shiftObservationUp(observationIndex);
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            disabled={observationIndex === 0}
                                                                                                                        >
                                                                                                                            <ArrowUpwardIcon />
                                                                                                                        </IconButton>
                                                                                                                        <IconButton
                                                                                                                            onClick={e => {
                                                                                                                                e.stopPropagation();
                                                                                                                                presentAlertDialog({
                                                                                                                                    message: `Déplacer ce constat vers le bas ?`,
                                                                                                                                    buttons: [
                                                                                                                                        {
                                                                                                                                            text: "Annuler"
                                                                                                                                        },
                                                                                                                                        {
                                                                                                                                            text: "Confirmer",
                                                                                                                                            handler: () => {
                                                                                                                                                dispatchReferential({
                                                                                                                                                    type: "update",
                                                                                                                                                    update: draft => {
                                                                                                                                                        let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                                        let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                                        let criterionTmp = objectiveTmp.criteria[criterionIndex];
                                                                                                                                                        criterionTmp.shiftObservationDown(observationIndex);
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            disabled={observationIndex === criterion?.observations?.length - 1}
                                                                                                                        >
                                                                                                                            <ArrowDownwardIcon />
                                                                                                                        </IconButton>
                                                                                                                        <IconButton onClick={e => {
                                                                                                                            e.stopPropagation();
                                                                                                                            setObservationDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 1, criterionNumber: criterionIndex + 1, observationNumber: observationIndex + 1, mode: "modify" });
                                                                                                                        }} >
                                                                                                                            <EditIcon />
                                                                                                                        </IconButton>
                                                                                                                        <IconButton onClick={e => {
                                                                                                                            e.stopPropagation();
                                                                                                                            setObservationDialogState({ isOpen: true, chapterNumber: chapterIndex + 1, objectiveNumber: objectiveIndex + 1, criterionNumber: criterionIndex + 1, observationNumber: observationIndex + 2, mode: "insert" });
                                                                                                                        }}>
                                                                                                                            <AddCircleIcon />
                                                                                                                        </IconButton>
                                                                                                                        {((status === "released" && observation.clientAction === "new") || status === "draft") && (
                                                                                                                            <IconButton
                                                                                                                                sx={{ marginRight: "40px" }}
                                                                                                                                onClick={e => {
                                                                                                                                    e.stopPropagation();
                                                                                                                                    presentAlertDialog({
                                                                                                                                        message: `Supprimer le constat ${observationIndex + 1} ?`,
                                                                                                                                        buttons: [
                                                                                                                                            {
                                                                                                                                                text: "Annuler"
                                                                                                                                            },
                                                                                                                                            {
                                                                                                                                                text: "Confirmer",
                                                                                                                                                handler: () => {
                                                                                                                                                    dispatchReferential({
                                                                                                                                                        type: "update",
                                                                                                                                                        update: draft => {
                                                                                                                                                            draft.chapters[chapterIndex].objectives[objectiveIndex].criteria[criterionIndex].deleteObservation(observationIndex);
                                                                                                                                                        }
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        ]
                                                                                                                                    })
                                                                                                                                }}>
                                                                                                                                <DeleteIcon />
                                                                                                                            </IconButton>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                                {((status === "released" && observation.clientAction !== "new") || status === "archived") && (
                                                                                                                    <IconButton
                                                                                                                        sx={{ marginRight: "40px" }}
                                                                                                                        disabled={status === "archived"}
                                                                                                                        onClick={e => {
                                                                                                                            e.stopPropagation();
                                                                                                                            presentAlertDialog({
                                                                                                                                message: observation.is_archived ? `Restaurer le constat ${observationIndex + 1} ainsi que ses parents ?` : `Archiver le constat ${observationIndex + 1} ?`,
                                                                                                                                buttons: [
                                                                                                                                    {
                                                                                                                                        text: "Annuler"
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        text: "Confirmer",
                                                                                                                                        handler: () => {
                                                                                                                                            dispatchReferential({
                                                                                                                                                type: "update",
                                                                                                                                                update: draft => {
                                                                                                                                                    let chapterTmp = draft.chapters[chapterIndex];
                                                                                                                                                    let objectiveTmp = chapterTmp.objectives[objectiveIndex];
                                                                                                                                                    let criterionTmp = objectiveTmp.criteria[criterionIndex];
                                                                                                                                                    let observationTmp = criterionTmp.observations[observationIndex];
                                                                                                                                                    if (observationTmp.is_archived) {
                                                                                                                                                        //if unarchive child, also unarchive its parents
                                                                                                                                                        observationTmp.unarchive();
                                                                                                                                                        criterionTmp.unarchive();
                                                                                                                                                        objectiveTmp.unarchive();
                                                                                                                                                        chapterTmp.unarchive();
                                                                                                                                                    } else {
                                                                                                                                                        observationTmp.archive();
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                        {observation.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                                                                                                    </IconButton>
                                                                                                                )}
                                                                                                            </ListItem>
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                            </List>
                                                                                        </Collapse>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </List>
                                                                    </Collapse>
                                                                </div>
                                                            );
                                                        })}

                                                    </List>
                                                </Collapse>
                                            </div>
                                        );
                                    })}
                                </List>
                            </>
                        }
                    </>
                }

            </Box>
            <Toolbar sx={{
                display: "flex",
                justifyContent: "right",
                position: "fixed",
                left: "0px",
                bottom: '0px',
                width: "100%",
                borderTop: "1px solid #cccccc",
                backgroundColor: "white",
                zIndex: 10
            }}>
                {status !== "archived" &&
                    <Button
                        sx={{ margin: "0 24px 0 24px" }}
                        disabled={
                            referential.isEmpty() ||
                            (status === "released" && referential.contain_empty_parents) ||
                            !referential?.is_modified
                        }
                        onClick={async () => {
                            let response = await httpRequest({
                                url: getServerUrl() + "/admin/referential/save",
                                data: { referential: referential, status: status },
                                headers: { "Content-type": "Application/json" },
                                method: 'post',
                                withCredentials: true
                            });
                            if (response.status === 200) {
                                presentToast({
                                    message: "sauvegarde effectuée avec succès",
                                    severity: "success",
                                });
                                let ref = new Referential(response.data);
                                dispatchReferential({
                                    type: "init",
                                    init: () => ref
                                });
                            }
                        }}>sauvegarder</Button>}
                {status === "draft" &&
                    <>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Button
                            sx={{ margin: "0 24px 0 24px" }}
                            disabled={
                                referential.isEmpty() ||
                                referential.contain_empty_parents ||
                                referential.is_modified
                            }
                            onClick={() => {
                                setReleaseDialogState({ isOpen: true });
                            }}>publier</Button>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Button
                            sx={{ margin: "0 24px 0 24px" }}
                            onClick={() => {
                                presentAlertDialog({
                                    message: "Êtes-vous sûr de vouloir importer le référentiel actuellement publié ?",
                                    buttons: [
                                        {
                                            text: "Annuler",
                                        },
                                        {
                                            text: "Confirmer",
                                            handler: async () => {
                                                let response = await httpRequest({
                                                    url: getServerUrl() + "/admin/referential/import",
                                                    method: 'get',
                                                    withCredentials: true
                                                });
                                                if (response.status === 200) {
                                                    if (Object.keys(response.data).length > 0) {
                                                        //force backup to current referential.chapters to maintain Referential.isModified() working properly
                                                        let ref = new Referential({ ...response.data, backup: { chapters: referential?.chapters } });
                                                        dispatchReferential({
                                                            type: "init",
                                                            init: () => ref
                                                        });
                                                    } else {
                                                        presentToast({
                                                            message: "Aucun référentiel publié actuellement",
                                                            severity: "error"
                                                        });
                                                    }

                                                }
                                            }
                                        }
                                    ]
                                })
                            }}>importer</Button>
                    </>
                }
                {status === "released" &&
                    <>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Button
                            sx={{ margin: "0 24px 0 24px" }}
                            disabled={
                                referential.isEmpty() ||
                                referential.contain_empty_parents ||
                                referential.is_modified
                            }
                            onClick={async () => {
                                setArchiveDialogState({ isOpen: true });
                            }}>archiver</Button>
                    </>
                }
            </Toolbar>
            <ChapterDialog
                isOpen={chapterDialogState.isOpen}
                chapterNumber={chapterDialogState.chapterNumber}
                mode={chapterDialogState.mode}
                onClose={() => setChapterDialogState(emptyChapterDialogState)}
                referential={referential}
                dispatchReferential={dispatchReferential}
            />
            <ObjectiveDialog
                isOpen={objectiveDialogState.isOpen}
                chapterNumber={objectiveDialogState.chapterNumber}
                objectiveNumber={objectiveDialogState.objectiveNumber}
                mode={objectiveDialogState.mode}
                onClose={() => setObjectiveDialogState(emptyObjectiveDialogState)}
                referential={referential}
                dispatchReferential={dispatchReferential}
            />
            <CriterionDialog
                isOpen={criterionDialogState.isOpen}
                chapterNumber={criterionDialogState.chapterNumber}
                objectiveNumber={criterionDialogState.objectiveNumber}
                criterionNumber={criterionDialogState.criterionNumber}
                mode={criterionDialogState.mode}
                onClose={() => setCriterionDialogState(emptyCriterionDialogState)}
                referential={referential}
                dispatchReferential={dispatchReferential}
            />
            <ObservationDialog
                isOpen={observationDialogState.isOpen}
                chapterNumber={observationDialogState.chapterNumber}
                objectiveNumber={observationDialogState.objectiveNumber}
                criterionNumber={observationDialogState.criterionNumber}
                observationNumber={observationDialogState.observationNumber}
                mode={observationDialogState.mode}
                onClose={() => setObservationDialogState(emptyObservationDialogState)}
                referential={referential}
                dispatchReferential={dispatchReferential}
            />
            <AddDialog
                isOpen={addDialogState.isOpen}
                onClose={() => setAddDialogState(emptyAddDialogState)}
                onValidate={handleAdd}
            />
            <ReleaseDialog
                isOpen={releaseDialogState.isOpen}
                onClose={() => setReleaseDialogState(emptyReleaseDialogState)}
                referential_immutable_id={referential?.immutable_id}
            />
            <ArchiveDialog
                isOpen={archiveDialogState.isOpen}
                onClose={() => setArchiveDialogState(emptyArchiveDialogState)}
                referential_immutable_id={referential?.immutable_id}
            />
        </Box >
    );

};

export default ReferentialAdmin;
