import { createContext, useContext, useState, useRef, useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHttp } from "./HttpProvider";
import { useUser } from "./UserProvider";
import { Typography } from '@mui/material';
import { getServerUrl } from '../config';


const MfaContext = createContext();

const MfaProvider = ({ children }) => {
    const [mfaStatus, setMfaStatus] = useState({ isMfaVerified: false, expireAt: undefined });
    const emptyTwoFADialogState = false;
    const [twoFADialog, settwoFADialog] = useState(emptyTwoFADialogState);
    const { httpRequest } = useHttp();
    const codeRef = useRef(null);
    const onCompleted = useRef(null);
    const { isLoggedIn } = useUser()

    useEffect(() => {

        if (!isLoggedIn) {
            return;
        }

        const checkBackendMfaStatus = async () => {
            let response = await httpRequest({
                url: getServerUrl() + "/admin/check_mfa_status",
                method: "get",
                withCredentials: true,
            });
            if (response.status === 200) {
                setMfaStatus(response.data);
            }
        }

        checkBackendMfaStatus();

    }, [isLoggedIn])

    useEffect(() => {
        console.log(mfaStatus);
    }, [mfaStatus])

    const requestEmailMfa = async (callback) => {
        if (!isMfaAuthed()) {
            let response = await httpRequest({
                url: getServerUrl() + "/admin/req_mfa",
                method: "get",
                withCredentials: true
            });
            if (response.status === 200) {
                onCompleted.current = callback;
                settwoFADialog(true);
            }
        } else {
            callback();
        }
    }


    const isMfaAuthed = () => {
        if (mfaStatus.isMfaVerified && mfaStatus.expireAt && Date.now() < mfaStatus.expireAt) {
            return true;
        }
        return false;
    }


    const handleVerify = async () => {
        let response = await httpRequest({
            url: `${getServerUrl()}/admin/check_mfa_code`,
            data: { code: codeRef.current.value },
            method: "post",
            headers: { "Content-type": "Application/json" },
            withCredentials: true,
        })
        if (response.status === 200) {
            setMfaStatus({ isMfaVerified: true, expireAt: response.data?.expireAt });
            onCompleted.current();
        }
        settwoFADialog(emptyTwoFADialogState);
    }


    const dismissDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            settwoFADialog(emptyTwoFADialogState);
        }
    }


    return (
        <MfaContext.Provider
            value={{
                isMfaAuthed,
                requestEmailMfa
            }}>
            <>
                <Dialog open={twoFADialog} onClose={dismissDialog}>
                    <DialogTitle>Authentification à 2 facteurs requise</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Un code vous a été envoyé par email. Veuillez le saisir ci-dessous afin de procéder à votre authentification à 2 facteurs.
                        </Typography>
                        <TextField
                            autoFocus
                            label="Code"
                            variant="standard"
                            inputRef={codeRef}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dismissDialog}>Annuler</Button>
                        <Button onClick={handleVerify} variant="contained">Confirmer</Button>
                    </DialogActions>
                </Dialog>
                {children}
            </>
        </MfaContext.Provider>
    );
}

const useMfa = () => useContext(MfaContext);

export { MfaProvider, useMfa };