import { createTheme } from '@mui/material/styles';
import { getServerUrl } from '../config';

const getEnv = () => {
    const serverUrl = getServerUrl();
    let env = "LOCAL";
    if (serverUrl.startsWith("https://api.e-qualiplus")) {
        env = "PROD";
    } else if (serverUrl.startsWith("https://api.qual")) {
        env = "QUAL"
    } else if (serverUrl.startsWith("https://api.dev")) {
        env = "DEV"
    }
    return env;
}

const getMainColor = () => {
    const env = getEnv();
    switch (env) {
        case "PROD": return "#127d78"
        case "QUAL": return "#2e96cc"
        case "DEV": return "#ff9e29"
        default: return "#8fb81f"
    }
}

const theme = createTheme({
    appbarHeight: "64px",
    palette: {
        primary: {
            main: getMainColor()
        },
    },
});

export default theme;